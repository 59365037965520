import { useFetch } from '../utils/fetch'
import { env } from '../env'

export const getPaginatedGroups = (options: any = { rows: 10, first: 10, pagination: true }) => {
    const params = new URLSearchParams({
        ...options,
        include: 'analytics',
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
    })

    return useFetch(`${env.BACKEND_V2_URL}/groups?${params}`).get().json()
}

export const getAllGroups = (options: any = { pagination: true }) => {
    const params = new URLSearchParams({
        ...options,
        include: 'analytics',
        pagination: false,
    })

    return useFetch(`/groups?${params}`).get().json()
}

export const postGroup = (body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/groups`).post(body).json()
}

export const patchLocationsToGroup = (id: string, body: object) => {
    const params = new URLSearchParams({
        include: 'location',
    })

    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}/locations?${params}`).patch(body).json()
}

export const deleteLocationsFromGroup = (id: string, locationId: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}/locations/${locationId}`).delete().text()
}

export const getGroupById = (id: string) => {
    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}?include=createdByUser&include=lastUpdatedByUser`).get().json()
}

export const deleteGroup = (id: string) => {
    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}`).delete().text()
}

export const getGroupPermissions = (id: string, options: any = { rows: 10, first: 10, pagination: false }) => {
    const params = new URLSearchParams({
        ...options,
        limit: options.rows || 10,
        offset: options.first || 0,
        include: 'location',
    })

    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}/permissions?${params}`).get().json()
}

export const putGroup = (id: string, body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}`).put(body).json()
}

export const getLocationsByGroupId = (id: string, options: any = { rows: 10, first: 10, pagination: true }) => {
    const params = new URLSearchParams({
        ...options,
        limit: options.rows || 10,
        offset: options.first || 0,
        include: 'location',
    })

    return useFetch(`${env.BACKEND_V2_URL}/groups/${id}/locations?${params}`).get().json()
}

export const deleteLocationFromGroup = (groupId: string, locationId: string) => {
    return useFetch(`/groups/${groupId}/locations/${locationId}`).delete().text()
}

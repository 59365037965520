<script setup>
import { env } from './env';
import { getDefaultLocale } from "~/helpers/locale";

const { currentRoute } = useRouter();

const { open } = useQuitWithoutSaving();

const hasSwitchedVersion = useLocalStorage('isPrevious', false);

const locale = useStorage('locale', getDefaultLocale());


watch(hasSwitchedVersion, (currentVal, previousVal) => {
    if (currentVal === false && previousVal === true) {
        window.location.href = 'https://app.posveloce.com/dashboard?switchVersion=true';
    }
});

watch(
    locale,
    (newLocale) => {
        document.documentElement.setAttribute('lang', newLocale);
    },
    { immediate: true }
);

useBeamer(env.BEAMER_ID);
</script>

<template>
    <div :id="(currentRoute.name || '').toLowerCase()" class="h-full">
        <Suspense>
            <RouterView></RouterView>
        </Suspense>
    </div>
    <Toast position="bottom-center"></Toast>

    <QuitWithoutSaving v-model="open"></QuitWithoutSaving>
    <NewAppVersion></NewAppVersion>
    <AppOffline></AppOffline>
    <ErrorDialog />
</template>

<style lang="scss">
.beamer_icon {
    background-color: black !important;
}
#beamerSelector {
    display: none;
}

.p-button:focus {
    box-shadow: none !important;
}


.cky-btn-revisit-wrapper {
    visibility: hidden !important;
}

div[data-cky-tag='detail-powered-by'] {
    display: none !important;
}

.bread {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: var(--gray-800);
}

.p-dialog-mask {
    z-index: 1000;
}

.p-multiselect-panel {
    z-index: 1000;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: var(--gray-700);
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: var(--gray-700);

        &.router-link-exact-active {
            color: var(--gray-800);
        }
    }
}
</style>

indicators:
  drawerOpenings:
    title: Ouverture tirroir
    description: Nombre de fois que le tiroir a été ouvert
  giftCardAmount:
    title: Montant des recharges de carte cadeau
    description: Montant total des recharges de cartes cadeaux
  itemCorrections:
    title: Corrections d'items
    description: Nombre de corrections d'items
  itemCorrectionsAmount:
    title: Montant des corrections d'items
    description: Montant total des corrections d'items
  loyaltiesAmount:
    title: Montant des loyautés
    description: Montant total des loyautés
  paidOuts:
    title: Paiements
    description: Nombre de paiements
  paidOutsAmount:
    title: Montant des paiements
    description: Montant total des paiements
  refunds:
    title: Remboursements
    description: Nombre de remboursements
  refundsAmount:
    title: Montant des remboursements
  voids:
    title: Annulations
    description: Nombre d'annulations
  voidsAmount:
    title: Montant des annulations
    description: Montant total des annulations
  grossSalesAmount:
    title: Montant des ventes brutes
    description: Montant total des ventes brutes
  discountsAmount:
    title: Montant des rabais
    description: Montant total des rabais
  invoicesDiscount:
    title: Rabais sur factures
    description: Montant total des rabais sur factures
  itemsDiscount:
    title: Rabais sur items
    description: Montant total des rabais sur items
  netSalesAmount:
    title: Montant des ventes nettes
    description: Montant total des ventes nettes
  refundsTotalAmount:
    title: Montant total des remboursements
    description: Montant total des remboursements
  taxesAmount:
    title: Montant des taxes
    description: Montant total des taxes
  gratuityAmount:
    title: Montant des pourboires
    description: Montant total des pourboires
  tipsAmount:
    title: Montant des pourboires
    description: Montant total des pourboires
  tipsTotalAmount:
    title: Tips total amount
    description: Total amount of tips
  customers:
    title: Clients
    description: Nombre de clients
  meals:
    title: Repas
    description: Nombre de repas
  orders:
    title: Commandes
    description: Nombre de commandes
  debitCreditTotalAmount:
    title: Montant total débit-crédit
    description: Montant total débit-crédit
  debitCreditTipsAmount:
    title: Montant des pourboires débit-crédit
    description: Montant total des pourboires débit-crédit
  averageNetSales:
    title: Ventes nettes moyennes
    description: Ventes nettes moyennes
  averageGrossSales:
    title: Ventes brutes moyennes
    description: Ventes brutes moyennes
  roundingAmount:
    title: Montant des arrondis
    description: Montant total des arrondis
  cashTipsAmount:
    title: Montant des pourboires en espèces
    description: Montant total des pourboires en espèces
  surchargeAmount:
    title: Montant des frais supplémentaires
    description: Montant total des frais supplémentaires
  netCashAmount:
    title: Montant net en espèces
    description: Montant net en espèces
  cashDepositAmount:
    title: Montant du dépôt en espèces
    description: Montant du dépôt en espèces
  paidInsAmount:
    title: Montant des paiements
    description: Montant total des paiements
  cashDiscountAmount:
    title: Montant des rabais en espèces
    description: Montant total des rabais en espèces
  locationSales:
    title: Ventes par établissement (top 10)
    description: Ventes par établissement (top 10)
  consolidatedHourlySales:
      title: Ventes par heure
      description: Ventes par heure

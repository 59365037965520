import { UserModule } from '~/types'
import { useRouterStore } from '~/stores/router'

export const install: UserModule = ({ router }) => {
    router.beforeEach((to, from) => {
        const { initialize } = useRouterStore()
        initialize(to)
    })
    router.afterEach(() => {})
}

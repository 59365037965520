<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { withDefaults } from 'vue';

const { t, locale } = useI18n();

const props = withDefaults(
    defineProps<{
        /**
         * Menu to display in the footer
         */
        menu: any;
        /**
         * Link of the brandname to display in the footer
         */
        brandName: string;
    }>(),
    {
        menu: [
            {
                name: 'Terms & conditions',
            },
            {
                name: 'Privacy policy',
            },
            {
                name: 'Contact us',
            },
        ],
        brandName: 'Payfacto. All rights reserved.',
    },
);

const emit = defineEmits([
    /**
     * Callback to invoke when the language is switched by the user
     *
     * @param {Event} event - Language selected.
     */
    'switchLanguage',
    /**
     * Callback to invoke when the menu item is clicked by the user
     *
     * @param {Event} event - Menu item.
     */
    'menuItemClick',
]);
const currentYear = new Date().getFullYear();

const onMenuItemClick = (menuItem) => {
    emit('menuItemClick', menuItem);
};

const changeLanguage = () => {
    emit('switchLanguage', locale.value === 'en' ? 'fr' : 'en');
};

const termsLink = computed(() => {
    return locale.value === 'en' ? 'https://payfacto.com/wp-content/uploads/2022/04/terms_and_conditions.pdf' : 'https://payfacto.com/wp-content/uploads/2022/04/termes_et_conditions.pdf';
})

const privacyLink = computed(() => {
    return locale.value === 'en' ? 'https://payfacto.com/privacy-policy/' : 'https://payfacto.com/fr/politique-de-confidentialite/';
})

const contactLink = computed(() => {
    return locale.value === 'en' ? 'https://payfacto.com/contact/' : 'https://payfacto.com/fr/contact/';
})
</script>
<template>
    <div class="flex w-full flex-column align-items-start lg:align-items-center justify-content-start lg:justify-content-center bg-white lg:bg-gray-200 w-full h-full">
        <div class="flex flex-auto w-full h-full flex-grow-1 justify-content-start  md:justify-content-center align-items-start lg:align-items-center flex-column text-center">
            <slot></slot>
        </div>
        <footer class="w-full align-items-center justify-content-cente  pt-6">
          <p><span v-html="t('By clicking on the Login button, or by accessing or otherwise using the service, you agree to be bound by the')"></span> <u style="cursor: pointer" @click="onMenuItemClick({ index: 0, menu: menu[0] })">{{ $t('Terms & Conditions') }}</u>.</p>
            <div class="flex flex-wrap flex-row mb-4 align-items-center justify-content-center">
                <template v-for="(menuItem, index) of menu" :key="index" >

                    <a class="white-space-nowrap" v-if="menuItem.external" @click="onMenuItemClick({ index, menuItem })" :href="menuItem.to || '#'" :target="menuItem.target || '_self'">{{ t(menuItem.name) }}</a>
                    <router-link class="white-space-nowrap" v-else @click="onMenuItemClick({ index, menuItem })" :to="menuItem.to || '#'" :target="menuItem.target || '_self'">{{ t(menuItem.name) }}</router-link>
                    <span class="px-2">|</span>
                </template>
                <a class="white-space-nowrap" :href="privacyLink" target="_blank">{{ t('Privacy Policy') }}</a>
                <span class="px-2">|</span>
                <a class="white-space-nowrap" :href="contactLink" target="_blank">{{ t('Contact Us') }}</a>
                <span class="px-2">|</span>
               <a href="javascript://" @click="changeLanguage">{{ locale === 'fr' ? 'English' : 'Français' }}</a>
            </div>
            <div class="copyright mb-4">©{{ currentYear }} {{ t(brandName) }}</div>
        </footer>
    </div>
</template>
<style lang="scss" scoped>
footer {
    justify-self: end;

    text-align: center;
    font: normal normal normal 12px/14px Roboto;
    color: #808080;

    .router-link-active,
    a {
        cursor: pointer;
        color: inherit;

        &:last-child:after {
            content: '';
        }
    }

    .copyright {
    }
}
</style>

<i18n lang="yaml">
en:
    Terms & Conditions: Terms & Conditions
    Privacy policy: Privacy policy
    Contact us: Contact us
fr:
    Terms & Conditions: Conditions générales
    Privacy policy: Politique de confidentialité
    Contact us: Contactez-nous
</i18n>

<script setup>
import { useI18n} from "vue-i18n";

const { t } = useI18n();

defineProps({
    title: { type: String, default: '' },
    subTitle: { type: String, default: '' },
    ii8n: { type: Object, default: () => ({
        username: 'test',

            last_update: 'test'
        })}
});
</script>

<template>
  <div>
    <h1>{{ t(title, ii8n) }}</h1>
    <p>{{ t(subTitle, ii8n) }}</p>
  </div>
</template>

<style lang="scss" scoped>
h1 {
    font: normal normal bold 20px/14px Roboto;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
    color: #404040;
}
p {
    font: normal normal normal 12px/14px Roboto;
    font-weight: normal;
    margin: 0;
}
</style>

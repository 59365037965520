import { DateTime } from 'luxon'

const utcDateTime = (fromDate: DateTime, zone = 'utc') => {
    return DateTime.fromObject(
        {
            year: fromDate.year,
            month: fromDate.month,
            day: fromDate.day,
            hour: fromDate.hour,
            minute: fromDate.minute,
            second: fromDate.second,
            millisecond: fromDate.millisecond,
        },
        { zone }
    )
}

export const now = () => {
    const localDateTime = DateTime.local()

    return utcDateTime(localDateTime).toISO()
}

export const startOf = (date = now()) => {
    const localDateTime = DateTime.fromISO(date)

    return utcDateTime(localDateTime).startOf('day').toISO()
}

export const endOf = (date = now()) => {
    const localDateTime = DateTime.fromISO(date)

    return utcDateTime(localDateTime).endOf('day').toISO()
}

export const utcLocalTime = (utfISOString: string) => {
    const dateTime = DateTime.fromISO(utfISOString, { zone: 'utc' })

    return utcDateTime(dateTime, 'local').toJSDate()
}

export const utcLocalTimeToHour = (utfISOString: string, format: string = 't') => {
    const dateTime = DateTime.fromISO(utfISOString, { zone: 'utc' })

    return utcDateTime(dateTime, 'local').toFormat(format)
}

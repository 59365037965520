import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useErrorStore = defineStore('error', () => {
    const errorMessage = ref('');
    const isError = ref(false);

    function setError(message) {
        errorMessage.value = message;
        isError.value = true;
    }

    function clearError() {
        errorMessage.value = '';
        isError.value = false;
    }

    return { errorMessage, isError, setError, clearError };
});

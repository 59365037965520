import router from '~/router'
import { useRouter } from 'vue-router'

export const useQuitWithoutSaving = createSharedComposable(() => {
    const state = {
        canSave: ref(false),
        open: ref(false),
        redirect: ref(null),
        msg: ref(null),
    }

    const { currentRoute } = useRouter()

    const isTracked = ref(false)
    const onOpenHandler = createEventHook<any>()
    let savehandler = () => {}

    const track = (trackedSave, handler) => {
        isTracked.value = true

        watch(
            trackedSave,
            (val) => {
                state.canSave.value = val
            },
            { immediate: true }
        )
        savehandler = handler
    }

    const cancel = () => {
        state.redirect.value = null
        state.open.value = false
        state.msg.value = null
    }

    const finish = async () => {
        state.canSave.value = false
        isTracked.value = false
        if (state.redirect.value) {
            await router.push(state.redirect.value)
        }
        cancel()
    }

    const saveAndQuit = async () => {
        await savehandler()
        await nextTick()
        await finish()
    }

    const quitWithoutSaving = async () => {
        state.canSave.value = false
        if (state.redirect.value) {
            await router.push(state.redirect.value)
        }
        cancel()
    }

    router.beforeEach(async (to, from, next) => {
        if (!isTracked.value) {
            return next()
        }

        if (!state.canSave.value) {
            isTracked.value = false
            next()
        } else {
            state.msg.value = (await onOpenHandler.trigger(true))[0]

            state.redirect.value = to
            state.open.value = true
            next(false)
        }
    })

    return {
        ...state,
        track,
        cancel,
        quitWithoutSaving,
        saveAndQuit,
        finish,
        onOpen: onOpenHandler.on,
    }
})

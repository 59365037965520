import { useFetch } from '@vueuse/core'
import { env } from '../env'

export const authenticate = ({ email, password }: any) => {
    return useFetch(env.BACKEND_V2_URL + '/users/authenticate')
        .post({ email, password })
        .text()
}

export const resetPasswordTokenUnauthenticated = (email: string, options: {}) => {
    const params = new URLSearchParams({
        ...options,
    })

    return useFetch(env.BACKEND_V2_URL + `/users/unauthenticated/${email}/resetToken?${params}`)
        .post()
        .text()
}

export const resetPassword = (body: any = {}) => {
    return useFetch(env.BACKEND_V2_URL + `/users/resetPassword`)
        .post(body)
        .text()
}

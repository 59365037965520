import 'nprogress/nprogress.css'
import { UserModule } from '~/types'
import { useMultiSelectorStore } from '~/stores/multi-selector'
import { endOf, startOf } from '~/helpers/date'
import { useRouter } from "vue-router";

const DEFAULT_CURRENCY = 'CAD'
const DASHBOARD_ROUTE_NAME = 'app-Dashboard'

export const install: UserModule = ({ router }) => {
    router.beforeEach(async (to, from, next) => {
        const { innitialize, currencies, reset } = useMultiSelectorStore()
        const { user, logout, updateUserPermissions } = useAuthStore()
        const { isAdmin } = storeToRefs(useAuthStore())

        const isPrevious = useLocalStorage('isPrevious', false);

        if(to.query.switchVersion) {
            isPrevious.value = true;
            delete to.query.switchVersion;
            return next(to);
        }

        if (from.name !== to.name) {
            reset()
        }

        if (!user?.id) {
            return next()
        }

        try {
            await updateUserPermissions()
            const locations = await innitialize(to || {})

            if (to.path !== from.path || !Object.keys(to.query).length) {

                const userSession = useLocalStorage('user', {})

                const user = useLocalStorage(userSession.value.id, { urlQueryParams: {} })

                if ([DASHBOARD_ROUTE_NAME].includes(to.name) && !Object.keys(to.query).length && Object.keys(user.value.urlQueryParams).length === 0) {
                    return next({
                        ...to,
                        query: {
                            ...to.query,
                            from: startOf(),
                            to: endOf(),
                            currency: currencies[0]?.name || DEFAULT_CURRENCY,
                            locationIDs: locations[0]?.id || null,
                            forceRedirect: false
                        },
                        replace: true,
                    })
                } else {
                }
            }
        } catch (e) {
            await logout(true)
        }

        next()
    })
}

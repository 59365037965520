import { useFetch } from '../utils/fetch'
import { env } from '../env'

export const getAllUsers = (options: any = { rows: 10, first: 10 }) => {
    if (!options.sort) {
        options.sort = 'fullName'
        options.order = 'asc'
    }
    const params = new URLSearchParams({
        ...options,
        pagination: true,
        limit: options.rows || 10,
        offset: options.first || 0,
    })

    if (options.downloadFileType) {
        params.delete('pagination')
        params.delete('limit')
        params.delete('offset')

        return useFetch(`${env.BACKEND_V2_URL}/users?${params}`).get().blob()
    }

    return useFetch(`${env.BACKEND_V2_URL}/users?${params}`).get().json()
}

export const getUsersPresets = (options: any = { rows: 10, first: 10 }) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/presets`).get().json()
}

export const putUserPresets = (body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/presets`).put(body).json()
}

export const getUserReportFilters = (reportId: number) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/reports/filters?reportId=${reportId}`).get().json()
}

export const deleteUserReportFilters = (reportId: number) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/reports/filters/${reportId}`).delete().json()
}

export const putUserReportFilters = (body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/reports/filters`).put(body).json()
}

export const deleteUserPresets = (id: string) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/presets/${id}`).delete().json()
}


export const getUserSettings = async () => {
    const { data } = await useFetch(`${env.BACKEND_V2_URL}/users/settings`).get().json()

    return (
        (data.value && data.value[0] && data.value[0].settings) || {
            'user-profile': JSON.stringify({}),
            'user-behavior': JSON.stringify({}),
        }
    )
}

export const patchUser = (id: string, body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/${id}`).patch(body).json()
}

export const postUser = (body: object, options: object = {}) => {
    const params = new URLSearchParams({
        ...options,
    })

    return useFetch(`${env.BACKEND_V2_URL}/users?${params}`).post(body).json()
}

export const getUserById = (id: string) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/${id}?include=createdByUser&include=lastUpdatedByUser`).get().json()
}

export const getUserPermissions = (id: string, options: object = {}) => {
    const params = new URLSearchParams({
        ...options,
    })

    return useFetch(`${env.BACKEND_V2_URL}/users/${id}/permissions?${params}`).get().json()
}

export const putUserPermissions = (id: string, body: object) => {
    return useFetch(`${env.BACKEND_V2_URL}/users/${id}/permissions`).put(body).json()
}

export const userPassswordResetToken = (email: string, options: object = {}) => {
    const params = new URLSearchParams({
        ...options,
    })
    return useFetch(`${env.BACKEND_V2_URL}/users/unauthenticated/${email}/resetToken?${params}`).post().text()
}

export const putUserSettingParam = (paramName: string, paramValue: string) => {

    const currentUser = JSON.parse(localStorage.getItem('user') || '{}')
    return useFetch(`${env.BACKEND_V2_URL}/users/settings/${paramName}`)
        .put({
            userId: currentUser.id,
            paramValue,
        })
        .text()
}

export const changePassword = (password: string, newPassword: string) => {
    const currentUser = JSON.parse(localStorage.getItem('user') || '{}')
    return useFetch(`${env.BACKEND_V2_URL}/users/${currentUser.id}/changePassword`)

        .post({
            password,
            newPassword,
        })
        .text()
}

<template>
    <button @click="test" v-ripple :class="buttonClass" type="button" :aria-label="defaultAriaLabel" :disabled="disabled || loading">
        <!-- @slot Content can easily be customized with the default slot instead of using the built-in modes. -->
        <slot>
            <span v-if="loading && !icon" :class="iconStyleClass"></span>
            <span v-if="icon" :class="iconStyleClass"></span>
            <span class="p-button-label">{{ label || '&nbsp;' }}</span>
            <span v-if="badge" :class="badgeStyleClass">{{ badge }}</span>
        </slot>
    </button>
</template>

<script>
import Ripple from 'primevue/ripple';

export default {
    name: 'Button',
    props: {
        /**
         * Text of the button.
         */
        label: {
            type: String,
            default: null,
        },
        /**
         * Name of the icon.
         */
        icon: {
            type: String,
            default: null,
        },
        /**
         * Position of the icon, valid values are 'left', 'right', 'bottom' and 'top'.
         */
        iconPos: {
            type: String,
            default: 'left',
        },
        /**
         * Style class of the icon.
         */
        iconClass: {
            type: String,
            default: null,
        },
        /**
         * Value of the badge.
         */
        badge: {
            type: String,
            default: null,
        },
        /**
         * Style class of the badge.
         */
        badgeClass: {
            type: String,
            default: null,
        },
        /**
         * Whether the button is in loading state.
         */
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Whether the button is in loading state.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Icon to display in loading state.
         */
        loadingIcon: {
            type: String,
            default: 'pi pi-spinner pi-spin',
        },
    },
    methods: {
        test() {
        },
    },
    computed: {
        buttonClass() {
            return {
                'p-button p-component': true,
                'p-button-icon-only': this.icon && !this.label,
                'p-button-vertical': (this.iconPos === 'top' || this.iconPos === 'bottom') && this.label,
                'p-disabled': this.$attrs.disabled || this.loading,
                'p-button-loading': this.loading,
                'p-button-loading-label-only': this.loading && !this.icon && this.label,
            };
        },
        iconStyleClass() {
            return [
                this.loading ? 'p-button-loading-icon ' + this.loadingIcon : this.icon,
                'p-button-icon',
                this.iconClass,
                {
                    'p-button-icon-left': this.iconPos === 'left' && this.label,
                    'p-button-icon-right': this.iconPos === 'right' && this.label,
                    'p-button-icon-top': this.iconPos === 'top' && this.label,
                    'p-button-icon-bottom': this.iconPos === 'bottom' && this.label,
                },
            ];
        },
        badgeStyleClass() {
            return [
                'p-badge p-component',
                this.badgeClass,
                {
                    'p-badge-no-gutter': this.badge && String(this.badge).length === 1,
                },
            ];
        },
        defaultAriaLabel() {
            return this.label ? this.label + (this.badge ? ' ' + this.badge : '') : this.$attrs['aria-label'];
        },
    },
    directives: {
        ripple: Ripple,
    },
};
</script>
<style lang="scss" scoped>
.p-button {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.p-button-label {
    flex: 1 1 auto;
}

.p-button-icon-right {
    order: 1;
}

.p-button:disabled {
    cursor: default;
}

.p-button-icon-only {
    justify-content: center;
}

.p-button-icon-only .p-button-label {
    visibility: hidden;
    width: 0;
    flex: 0 0 auto;
}

.p-button-vertical {
    flex-direction: column;
}

.p-button-icon-bottom {
    order: 2;
}

.p-buttonset .p-button {
    margin: 0;
}

.p-buttonset .p-button:not(:last-child) {
    border-right: 0 none;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
}

.p-buttonset .p-button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.p-buttonset .p-button:focus {
    position: relative;
    z-index: 1;
}
</style>

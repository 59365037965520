<script setup>
import { useQuitWithoutSaving } from '~/composables/useQuitWithoutSaving'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)

const { open, msg, cancel, saveAndQuit, quitWithoutSaving } = useQuitWithoutSaving()

const { t } = useI18n()

const isSm = breakpoints.smallerOrEqual('sm')
</script>

<template>
    <Dialog
        id="quit-without-saving"
        v-model:visible="open"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        class="pb-0"
        :closable="true"
        :modal="true"
        :style="{
            width: '100%',
            maxWidth: '400px',
            height: 'auto',
            zIndex: 10000,
        }"
        @hide="cancel"
        ref="d"
    >
        <template #header>
            <Title class="title" :title="t('Warning!')"></Title>
        </template>
        <p v-if="msg" class="text-center" v-html="msg" />
        <p class="text-center">
            {{ t('Your changes will be lost if you do not save them.') }}
        </p>
        <div class="flex flex-column flex-column-reverse md:flex-row justify-content-between pt-3">
            <Button
                class="secondary mt-3 md:mt-0 w-full md:w-auto border-round-3xl border-primary md:border-white md:border-round h-3rem md:h-auto"
                :class="{ 'bg-white': isSm }"
                :label="t('Quit without saving')"
                @click="quitWithoutSaving"
            />
            <Button
                class="w-full md:w-auto border-round-3xl md:border-round h-3rem md:h-auto"
                :label="t('Save & quit')"
                @click="saveAndQuit"
            />
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-center">
                <Button
                    class="p-button-rounded p-button-text mt-0 md:mt-0 w-full md:w-auto border-round-3xl border-primary md:border-white bg-white md:border-round h-3rem md:h-auto"
                    icon="pi-angle-left pi"
                    @click="cancel"
                >
                    <svg
                        class="mr-2"
                        height="12"
                        viewBox="0 0 6.834 12"
                        width="6.834"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            id="IMG_Arrow"
                            d="M5.985,0a.776.776,0,0,0-.291.053A.805.805,0,0,0,5.43.238L.2,5.469a.727.727,0,0,0-.2.568A.813.813,0,0,0,.225,6.6a.663.663,0,0,0,.568.225.993.993,0,0,0,.542-.251l4.65-4.65,4.65,4.65a.863.863,0,0,0,.568.238.625.625,0,0,0,.568-.238.582.582,0,0,0,.225-.555,1.029,1.029,0,0,0-.251-.581l-5.2-5.2A.805.805,0,0,0,6.275.053.776.776,0,0,0,5.985,0Z"
                            fill="var(--primary-color)"
                            transform="translate(0 12) rotate(-90)"
                        />
                    </svg>
                    {{ t('Back') }}
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<style lang="scss" scoped>
p {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: var(--gray-900);
    padding: 0 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    align-items: center;
    vertical-align: middle;
    justify-content: left;
    margin-left: 1rem;

    &::marker {
        color: var(--primary-color);
        position: relative;
        margin-top: -2px;
    }
}

.grid {
    border-bottom: 1px dashed var(--tofix-3);
}

.p-fileupload {
    :deep(.p-button-icon) {
        display: none;
    }

    :deep(.p-button) {
        background: var(--surface-a) 0% 0% no-repeat padding-box;
        text-align: left;
        border: 1px solid var(--tofix-3);
        font: normal normal normal 12px/19px Roboto;
        white-space: nowrap;
        color: var(--gray-900);
    }
}

button.secondary {
    background: var(--gray-200) 0% 0% no-repeat padding-box;
    border-color: var(--gray-200);
    color: var(--primary-color);
}

h3 {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: var(--primary-color);
}

:deep(h1) {
    font: normal normal bold 14px/19px Roboto;
}

:deep(p) {
    font: normal normal normal 14px/16px Roboto;
}

label {
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: var(--gray-700);
}

:deep(.p-dialog-footer) {
    padding-bottom: 0 !important;
}

:global(#quit-without-saving .p-dialog-content) {
    padding-bottom: 1rem !important;
}
</style>

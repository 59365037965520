import { UserModule } from "~/types";

export const install: UserModule = ({ router }) => {
    router.beforeEach(async (to, from, next) => {
        if((typeof to.query.locationIDs === 'string' || to.query.locationIDs?.length === 1)) {
            const id = typeof to.query.locationIDs === 'string' ? to.query.locationIDs : to.query.locationIDs[0];

            if(to.query.revenueCenter === 'false' && to.query.locationId !== id) {
                return next({
                    ...to,
                    query: {
                        ...to.query,
                        locationId: id,
                        level: 1
                    }
                });
            }

            if(to.query.revenueCenter === 'true' && to.query.locationId !== id) {
                return next({
                    ...to,
                    query: {
                        ...to.query,
                        locationId: id,
                        level: 1
                    }
                });
            }

        }
        return next();
    })
}

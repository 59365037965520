<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import { useNetwork } from "@vueuse/core";

const { t } = useI18n();

const { isOnline } = useNetwork();
const isOffline = ref(!navigator.onLine);

watch(isOnline, (value) => {
    isOffline.value = !value;
});
</script>

<template>
    <Dialog
        v-model:visible="isOffline"
        class="connection-lost-dialog"
        :closable="true"
        :header="t('Connection Lost')"
        modal
        style="width: 100%; max-width: 400px"
    >
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="mb-4" style="width: 200px; height: 139px; position: relative">
                <svg height="139" viewBox="0 0 200 139" width="200" xmlns="http://www.w3.org/2000/svg">
                    <path id="IMG_Glow" d="M169.5,69.5A69.5,69.5,0,1,1,100,0a69.5,69.5,0,0,1,69.5,69.5M18,69.75A3.75,3.75,0,0,0,14.25,66H3.75a3.75,3.75,0,1,0,0,7.5h10.5A3.75,3.75,0,0,0,18,69.75M196.25,66h-10.5a3.75,3.75,0,0,0,0,7.5h10.5a3.75,3.75,0,0,0,0-7.5M23.557,109.369l-8.77,5.063a3.8,3.8,0,1,0,3.8,6.578l8.77-5.063a3.8,3.8,0,1,0-3.8-6.577m152.886-79.5,8.77-5.063a3.8,3.8,0,0,0-3.8-6.577l-8.77,5.063a3.8,3.8,0,0,0,3.8,6.578M27.354,23.293l-8.77-5.063a3.8,3.8,0,1,0-3.8,6.577l8.77,5.063a3.8,3.8,0,1,0,3.8-6.577m157.858,91.139-8.77-5.063a3.8,3.8,0,0,0-3.8,6.577l8.77,5.063a3.8,3.8,0,0,0,3.8-6.578" fill="var(--gray-200)"/>
                </svg>

                <svg height="67.594" style="position: absolute; top: 50%; left: 50%; margin: -33px 0 0 -40px" viewBox="0 0 80 67.594" width="80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <linearGradient id="linear-gradient" gradientUnits="objectBoundingBox" x2="1" y2="1">
                            <stop offset="0" stop-color="#006cc5"/>
                            <stop offset="1" stop-color="var(--primary-color)"/>
                        </linearGradient>
                    </defs>
                    <path id="IMG_No-Network" d="M45.622,61.958a5.629,5.629,0,1,1-5.629-5.629,5.629,5.629,0,0,1,5.629,5.629M32.375,25.035A36.357,36.357,0,0,0,14.1,34.962,4.48,4.48,0,0,0,20.441,41.3a27.489,27.489,0,0,1,12.271-7.133ZM31.707,6.759a54.493,54.493,0,0,0-30.4,15.4,4.48,4.48,0,1,0,6.336,6.336A45.418,45.418,0,0,1,32.043,15.778Zm1.316,36.034a22.034,22.034,0,0,0-8.683,5.364,4.48,4.48,0,0,0,6.336,6.336,13.2,13.2,0,0,1,3.369-2.447,6.726,6.726,0,0,1-.787-2.928ZM78.676,22.155a54.493,54.493,0,0,0-30.391-15.4l-.329,9.015A45.418,45.418,0,0,1,72.351,28.488a4.48,4.48,0,1,0,6.336-6.336M47.618,25.035l-.334,9.13a27.489,27.489,0,0,1,12.271,7.127,4.48,4.48,0,1,0,6.336-6.336,36.357,36.357,0,0,0-18.27-9.927m-.648,17.758-.231,6.324a6.726,6.726,0,0,1-.787,2.928,13.2,13.2,0,0,1,3.362,2.454,4.48,4.48,0,0,0,6.336-6.336,22.034,22.034,0,0,0-8.683-5.367M36.242,49a3.76,3.76,0,0,0,7.514,0L45.34,5.539a5.348,5.348,0,1,0-10.688,0Z" fill="url(#linear-gradient)" transform="translate(0.001 0.007)"/>
                </svg>

            </div>
            <p class="text-lg text-center">
                {{
                    t(
                        "The internet connection has been lost and that some functionalities may not be available until the connection is restored.",
                    )
                }}
            </p>
            <Button class="mb-2" @click="isOffline = false">{{ t('Continue') }}</Button>
        </div>
    </Dialog>
</template>

<style lang="scss">
.connection-lost-dialog {
    .p-dialog-title {
        font: normal normal bold 14px/19px Roboto !important;
    }

    .p-dialog-content {
        background-color: transparent !important;
    }
}

.p-button:focus {
    box-shadow: none !important;
}

</style>

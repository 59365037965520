<template>
    <div class="p-legalinformation">
        <h1 v-if="showTitle">{{ title }}</h1>
        <slot> </slot>
    </div>
</template>

<script>
export default {
    name: 'LegalInformation',
    props: {
        title: {
            type: String,
            default: null,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="scss">
.p-dialog .p-dialog-content {
    padding: 0 2rem 2rem 2rem !important;
}

.p-dialog .p-dialog-header {
    padding: 2rem !important;
}
.p-legalinformation {
    padding: 0 0;

    h1, h3 {
        margin-top: 0;
    }

    * {
        text-align: justify !important;
    }

    ol {
        background: 0 0;
        border: 0;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        outline: 0;

        li {
            display: list-item;
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0 0 1.6rem 0;
        }
        li::marker {
            font-weight: normal;
            content: ''
        }
    }

}

</style>



import __layout_0 from '/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/default.vue'
export const layouts = {
'404': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/404.vue'),
'default': __layout_0,
'home-no-scroll': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/home-no-scroll.vue'),
'home-scroll': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/home-scroll.vue'),
'home': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/home.vue'),
'pagination': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/pagination.vue'),
'user-profile-layout': () => import('/home/runner/work/pay-ui/pay-ui/apps/velweb-frontend/src/layouts/user-profile-layout.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}

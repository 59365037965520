import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useRouterStore = defineStore('router', () => {
    const meta = ref({})

    const initialize = async (to) => {
        meta.value = to.meta || {}
    }

    const updateMeta = (updatedMeta) => {
        meta.value = { ...meta.value, ...updatedMeta }
    }

    return { meta, updateMeta, initialize }
})

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useRouterStore, import.meta.hot))

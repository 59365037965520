<script setup>
import { useI18n } from 'vue-i18n';
import LegalInformation from 'pay-core/legalinformation';
import Dialog from 'pay-core/dialog';
import { computed, ref } from 'vue';

const { t, locale } = useI18n();

const visible = ref(true);

const props = defineProps({
    /**
     * Text to display in the title
     */
    title: { type: String, default: 'title' },
    /**
     * State of the dialog
     */
    visible: { type: Boolean, default: true },
});

const emit = defineEmits([
    /**
     * Emitted when the value changes.
     * @param {Event} event - New value.
     */
    'update:visible',
]);

const isVisible = computed({
    get() {
        return props.visible;
    },
    set(value) {
        emit('update:visible', value);
    },
});
</script>
<template>
    <Dialog :header="t('title')" v-model:visible="isVisible" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }" :modal="true" class="pb-4">
        <LegalInformation :showTitle="false">
            <slot>
                <div v-if="locale === 'en'">
                    <p><strong>1.&nbsp; </strong><b>Our commitment</b></p>

                    <p>
                        We value the trust you have placed in our organization and are committed to prioritizing and maintaining the accuracy, confidentiality and security of personal information. We will not collect, use or disclose your personal
                        information without your informed consent, unless required or permitted by law. Read this privacy policy (“Privacy Policy”) to understand how we use, share and store your data.
                    </p>

                    <p>
                        <strong
                            ><span style="color: #535353"><span lang="en-US">2. </span></span></strong
                        ><span lang="en-US"
                            ><b><span style="color: #535353">Our Politics</span></b></span
                        >
                    </p>

                    <p>In this Privacy Policy, “PayFacto”, “we”, “us” or “our” refers to PayFacto Payments Inc. All services mentioned are services provided by PayFacto.</p>

                    <p>
                        “You” refers to anyone who visits our website(s), our third-party customers and suppliers, and anyone who engages with our services. It is our duty to inform you of what we do with your personal information and why, and to
                        ensure that you are well informed so that you can give us meaningful consent.
                    </p>

                    <p>
                        For the purposes of this Privacy Policy, “personal information” means information that: (i) can directly identify an individual; or (ii) is information about an identifiable individual. This does not include information that
                        is used solely for the purpose of communicating or facilitating communication with an individual in relation to their employment, business or profession.
                    </p>

                    <p>
                        <strong
                            ><span style="color: #535353"><span lang="en-US">3.&nbsp;</span></span
                            >Acceptance of the terms of our privacy policy</strong
                        >
                    </p>

                    <p>
                        By providing personal information to PayFacto, using our websites, including <a href="http://www.payfacto.com">www.payfacto.com</a> (collectively, the “Website”), or interacting with our social media, you consent to our use,
                        collection and disclosure of your personal information in accordance with the terms of this Privacy Policy. Providing personal information or authorizing a third party to disclose personal information to us signifies your
                        consent to PayFact’s collection, use and disclosure of personal information in accordance with this Privacy Policy<span style="color: #535353"><span lang="en-US">.</span></span>
                    </p>

                    <p>
                        <strong
                            ><span style="color: #535353"><span lang="en-US">4.&nbsp;</span></span
                            >Types of Information Collected</strong
                        >
                    </p>

                    <p>The type of information that may be collected includes, but is not limited to:</p>

                    <ul>
                        <li>Contact details such as your name, residential address, telephone number, email address – we use them to communicate with you.</li>
                        <li>
                            Identity information such as your date of birth, gender, social insurance number, other government identification (such as driver’s license or passport information), employment, and information about other financial
                            accounts (if used to verify your identity).
                        </li>
                        <li>Information about your current and past employment, income and assets – we use it to assess your suitability as a customer and your eligibility for credit-related products and services.</li>
                        <li>
                            Information about how you use our products and services – we use it to provide you with these products and services, to protect ourselves and your account (by spotting unusual activity) and to notify you of changes that
                            will impact your products and services. We also use it to provide you with personalized products and services, to better understand your needs and preferences (including through data analytics), to personalize your
                            experience on our websites and mobile applications, and to provide you with personalized communications and offers.
                        </li>
                        <li>Information about another individual – if you have a joint account or a legal guardian or personal representative, we will also collect personal information about those individuals.</li>
                        <li>Other information you voluntarily provide to us, our employees, service providers or subcontractors.</li>
                    </ul>

                    <p>Information will be collected by fair and lawful means. We do not collect more information than we need.</p>

                    <p>
                        <strong>5.</strong>
                        <b
                            >Why we collect your information<i><br /> </i
                        ></b>
                    </p>

                    <p>Here are some examples of why we collect personal information and how we use it:</p>

                    <ul>
                        <li>to verify your identity, including to comply with our “know your customer”, anti-money laundering and sanctions obligations;</li>
                        <li>to assess and process your account or credit limit application, including to determine your eligibility;</li>
                        <li>to obtain your credit report;</li>
                        <li>to communicate with you about your loans, securities or deposits;</li>
                        <li>to make changes you request to your account;</li>
                        <li>to process, track and report to you;</li>
                        <li>to detect and prevent theft, fraud and error, and to protect you and others from theft, fraud and error;</li>
                        <li>to analyze the information to determine that the relevant services are available to you;</li>
                        <li>to provide you with products and services, or information about products and services, that have been requested by you and/or that, in PayFacto’s opinion, may be of interest and benefit to you;</li>
                        <li>to inform you of new business initiatives, including by contacting you for your views and to encourage you to express your views on them;</li>
                        <li>to offer you the opportunity to participate in contests, giveaways or other promotions;</li>
                        <li>to conduct research and generate statistics related to our activities, products and services;</li>
                        <li>
                            for business purposes, such as data analysis, audits, developing new products, improving, improving, or modifying our services, identifying usage trends, determining the effectiveness of our promotional campaigns, and
                            operating and expanding our business activities;
                        </li>
                        <li>to help you manage and assess our risks, operations and relationships with you;</li>
                        <li>to obtain and maintain insurance coverage;</li>
                        <li>to comply with legal and governmental requirements;</li>
                        <li>to fulfill any other purpose for which you provide it or with your consent; and</li>
                        <li>
                            If you have a product that generates income and we have your SIN, we may use it for tax reporting purposes and share income information with the Canada Revenue Agency. With our consent, we may also use your SIN to match
                            your personal information with your credit bureau information, or as an internal identification number to accurately identify customers with the same or similar name.
                        </li>
                    </ul>

                    <p>
                        <span style="color: #535353"><span lang="en-US">We will obtain your consent before proceeding with any other purpose not identified in this Privacy Policy.</span></span>
                    </p>

                    <p>
                        <strong
                            ><span style="color: #535353"><span lang="en-US">6.&nbsp;</span></span
                            >How we collect your information</strong
                        >
                    </p>

                    <p>
                        Personal information may be collected in a number of ways, including: through our website, in person, by mail, email, from our applications or other software, and from third parties that you have authorized to disclose
                        personal information to us.
                    </p>

                    <p>
                        If you provide information about a third party or authorize a third party to do so, we will assume that you have taken appropriate steps to obtain informed consent<span style="color: #535353"><span lang="en-US">.</span></span>
                    </p>

                    <p>
                        <strong
                            ><span style="color: #535353"><span lang="en-US">7. </span></span></strong
                        ><span lang="en-US"
                            ><b><span style="color: #535353">Who we share your information with</span></b></span
                        >
                    </p>

                    <p>PayFacto may share your information with third parties.&nbsp; PayFacto will do this to support the provision of our services, the administration of our business, and for any other purpose set out below, including:</p>

                    <ul>
                        <li>With affiliates: To help us provide you with the best services, we may share your personal information with our other respective companies and affiliates.</li>
                        <li>
                            With our employees: In the performance of their duties, our authorized employees may have access to personal and other confidential information.&nbsp; Employees may only access personal information that is strictly
                            necessary for the performance of their duties. &nbsp;All employees who may have access to personal information must sign a code of conduct committing to keep it confidential.
                        </li>
                        <li>
                            With third-party product and service providers: We may disclose personal information to certain third-party product and service providers we have retained to help you provide you with products and services, or to perform
                            certain specialized services to assist us in our business.
                            <ul>
                                <li>
                                    We only disclose to these providers the specific information they need to provide the Services.&nbsp;Before disclosing personal information to them, each of our suppliers must agree to use the information only to
                                    perform the services they have been retained to provide, must agree to protect and respect the confidentiality of the information, and must agree to contractual terms that are consistent with our Privacy Policy.
                                    Third-party providers must also comply with applicable Canadian privacy laws.
                                </li>
                                <li>
                                    When we use these providers, we may process, store and transfer your personal information in and to a foreign country, with different privacy laws that may or may not be as comprehensive as Canadian law. In these
                                    circumstances, governments, courts, law enforcement or regulatory agencies in that country may be able to obtain access to your personal information through the laws of the foreign country. Whenever we engage a
                                    service provider in another country, we require them to comply with our Privacy Policy and applicable Canadian privacy laws.
                                </li>
                            </ul>
                        </li>
                        <li>Business transfers: As we continue to grow, we may expand or sell our businesses or parts of them.&nbsp;In such a case, the personal information we hold may be among the transferred assets.</li>
                        <li>
                            We may also disclose personal information in situations where we are legally required or permitted to do so. These situations may include criminal investigations, government tax reporting requirements, court orders, or
                            instances where we believe the rights and safety of others may be at risk. A court may order us to disclose personal information to a third party or to the court, or to retain personal information pending the outcome of a
                            legal hearing. We may also be legally required to disclose information in connection with the collection or repayment of a debt, to assist in the prevention of fraud or other criminal activity, when we obtain legal advice
                            from a lawyer, or in an emergency that affects the health or safety of a person.
                        </li>
                    </ul>

                    <p>
                        In the cases described above, we may be required to share your personal information with or without your consent. In limited circumstances, we may be legally prohibited from informing you that your information has been shared
                        in this way.
                    </p>

                    <p>
                        If you believe that a third party has improperly disclosed your personal information to us, please contact that third party directly. If the third party does not respond sufficiently to your requests, please inform us
                        immediately.
                    </p>

                    <p>
                        <strong><span lang="en-US">8. </span></strong><span lang="en-US"><b>Accuracy</b></span>
                    </p>

                    <p>
                        We make every reasonable effort to keep your personal information as accurate, complete and up-to-date as necessary. We encourage ourselves to verify the accuracy and completeness of your personal information in our records.
                    </p>

                    <p>
                        Despite our efforts, mistakes sometimes happen. If you identify incorrect or outdated information in your file, we will remedy such errors in a timely manner. In the event that inaccurate information is mistakenly sent to a
                        third party, we will communicate the relevant changes to the third party where appropriate.
                    </p>

                    <p><strong>9. Protecting Your Personal Information</strong></p>

                    <p>Our employees and contractors understand the importance of keeping your information private. All employees and contractors are expected to maintain the confidentiality of personal information.</p>

                    <p>To ensure that your personal information is protected, we use security measures that comply with industry standards. We keep electronic files in a secure environment with restricted access and use.</p>

                    <p>
                        Despite all the aforementioned security measures, the risk of malicious intrusion remains a technological reality that any company must face. We encourage all individuals to provide only the information they are comfortable
                        providing to a third party, to monitor suspicious communications, and to report suspicious activity to us as soon as possible. As a community, we can all help keep them safe.
                    </p>

                    <p><strong>10. Withdrawal</strong></p>

                    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>

                    <ul>
                        <li>You can write to or email us using the contact details below to withdraw your consent for us to use your personal information for direct marketing purposes.</li>
                        <li>If you receive a commercial email from us, you may also unsubscribe using the unsubscribe tool we provide in these messages.</li>
                    </ul>

                    <p>
                        Please note that if you seek to withdraw your consent to the collection and processing of information that we need to process to provide our services, we may not be able to provide you with those services. For example, you may
                        not withdraw your consent to the ongoing collection, use and disclosure of personal information necessary to administer a loan product you have purchased. Similarly, you may not withdraw your consent to the collection, use or
                        disclosure of information we need to fulfill regulatory purposes.
                    </p>

                    <p><strong>11. Cookies</strong></p>

                    <p>
                        We may also collect information through cookies, also known as “cookies” on our website. Cookies are small text files inside your computer that contain a unique identifier, which allows us to recognize you when you visit our
                        website again. This helps us tailor our content to meet your needs and for advertising purposes. You can refuse to accept cookies by activating the setting on your browser that allows you to refuse the installation of cookies.
                        However, if you select this setting, you may not be able to access certain parts of our website. By accepting cookies and continuing to visit or use our website, you agree to the use of cookies and similar technologies for the
                        purposes we describe in this Privacy Policy.
                    </p>

                    <p>
                        Cookies may be used to: (i) recognize you when you visit the Website, remember your preferences and provide you with a personalized experience in accordance with your settings, (ii) make your interactions with the Website
                        faster and more secure, and (iii) provide you with advertising and personalized experiences through personalization. In addition, cookies are used for authentication, security and search.
                    </p>

                    <p>
                        To find out more about cookies, including how to see which cookies have been set and how to manage and delete them, visit <a href="http://www.wikipedia.org/">wikipedia.org</a>,
                        <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a> or <a href="http://www.aboutcookies.org/">aboutcookies.org</a>.
                    </p>

                    <p><strong>12. Social Media</strong></p>

                    <p>If you post a review, comment or submit personal information in a public forum such as social media accounts or public forums on our website or other applications, your communications may be visible to the public.</p>

                    <p><strong>13. Updates to this Privacy Policy</strong></p>

                    <p>
                        PayFacto strives to evolve to meet the needs and expectations of our customers, which means that our services, the way we offer our products and the way we communicate information may change. With this in mind, we review our
                        privacy practices from time to time, and our information handling practices may change. If PayFacto makes a material change to this Privacy Policy, we will provide a revised version on our website and indicate the date on
                        which the changes were made. Changes will be effective upon posting of such changes on our website. You can determine when this Privacy Policy was last updated by referring to the date found at the bottom of this Privacy
                        Policy. If at any time you do not agree with the terms of this Privacy Policy, you must not use the PayFacto platform, website or services.
                    </p>

                    <p><strong>14. Website and applications governed by this Privacy Policy</strong></p>

                    <p>
                        Our website and all of our applications available on our website or on third-party platforms are governed by the terms and practices set forth in this Privacy Policy. Our website and these applications may contain links to
                        third-party sites or applications that are not governed by this Privacy Policy. While we strive to only link to sites or applications that share our commitment to your privacy, please note that this Privacy Policy will no
                        longer apply once you leave our website or such applications, and that we are not responsible for the privacy practices of any third-party sites or applications. We therefore suggest that you closely review the respective
                        privacy policies of third-party sites and applications to learn how they collect, use and disclose your personal information.
                    </p>

                    <p><strong>15. Governing Law</strong></p>

                    <p>This Privacy Policy and all related matters shall be interpreted and construed in accordance with the laws of the Province of Quebec and the applicable federal laws of Canada.</p>

                    <p><strong>16. Personal Information Outside Canada</strong></p>

                    <p>
                        Personal information provided to our service providers may be stored outside Quebec and outside of Canada. You acknowledge and agree that, as a result, your personal information may be processed, used, stored or accessed in
                        other jurisdictions and may be subject to the laws of those jurisdictions. For example, information may be disclosed in response to valid requests or requests from government authorities, courts or law enforcement agencies in
                        other countries.
                    </p>

                    <p><strong>17. Responding to you inquiriess and concerns</strong></p>

                    <p>We are happy to provide you with a copy of this Privacy Policy and discuss any content with you.</p>

                    <p>We have appointed our Vice President of Legal Affairs as our Privacy Officer who is responsible for maintaining and implementing our Privacy Policy. Please direct any questions or inquiries regarding this Privacy Policy to:</p>

                    <p>Attention: Privacy Officer</p>

                    <p>Email: <a href="mailto:legal@payfacto.com">legal@payfacto.com</a></p>

                    <p>
                        PayFacto Payments Inc.<br />
                        1, Place du Commerce #402<br />
                        Montreal (Quebec) H3E 1A2<br />
                        Telephone (toll-free): 877 341-8293
                    </p>

                    <p>______________________________________</p>

                    <p>Last revised: November 2022</p>
                    <p><strong>&nbsp;</strong></p>
                </div>
                <div v-if="locale === 'fr'">
                    <p>
                        <strong><span style="color: #535353">1.&nbsp; </span></strong><b><span style="color: #535353">Notre engagement</span></b
                        ><b></b>
                    </p>

                    <p>
                        Nous apprécions la confiance que vous avez placée dans notre organisation et nous nous engageons à prioriser et à maintenir l’exactitude, la confidentialité et la sécurité des renseignements personnels. Nous ne recueillerons,
                        n’utiliserons, ni ne divulguerons vos renseignements personnels sans votre consentement éclairé, à moins que la loi ne l’exige ou ne le permette. Lisez cette politique de confidentialité («&nbsp;Politique de
                        confidentialité&nbsp;») afin de comprendre comment nous utilisons, partageons et stockons vos données<span style="color: #535353">.</span>
                    </p>

                    <p>
                        <strong><span style="color: #535353">2. </span></strong
                        ><b
                            ><span style="color: #535353">Notre Politique<br /> </span
                        ></b>
                    </p>

                    <p>Dans la présente politique de confidentialité, « PayFacto », « nous » ou « nos » fait référence à PayFacto Payments Inc. Tous les services mentionnés sont des services fournis par PayFacto.</p>

                    <p>
                        «&nbsp;Vous&nbsp;» fait référence à toute personne qui visite notre (nos) site (s) Web, à nos clients et fournisseurs tiers, et à toute personne qui s’engage avec nos services. Il est de notre devoir de vous informer de ce que
                        nous faisons avec vos renseignements personnels et pourquoi, et de nous assurer que vous êtes bien informé afin que vous puissiez nous donner un consentement valable.
                    </p>

                    <p>
                        Aux fins de la présente politique de confidentialité, les « renseignements personnels » désignent les renseignements qui : (i) peuvent identifier directement une personne; ou (ii) sont des informations sur un individu
                        identifiable. Cela ne comprend pas les renseignements qui sont utilisés uniquement dans le but de communiquer ou de faciliter la communication avec une personne relativement à son emploi, à son entreprise ou à sa
                        profession<span style="color: #535353">.</span>
                    </p>

                    <p>
                        <strong><span style="color: #535353">3. </span></strong><b><span style="color: #535353">Acceptation des modalités de notre politique de confidentialité</span></b>
                    </p>

                    <p>
                        En fournissant des renseignements personnels à PayFacto, en utilisant nos sites Web, y compris <a href="http://www.payfacto.com">www.payfacto.com</a> (collectivement, le « site Web »), ou en interagissant avec nos médias
                        sociaux, vous consentez à ce que nous utilisions, collections et divulguions vos renseignements personnels conformément aux modalités de la présente politique de confidentialité. Le fait de fournir des renseignements
                        personnels ou d’autoriser un tiers à nous divulguer des renseignements personnels signifie votre consentement à la collecte, à l’utilisation et à la divulgation de renseignements personnels par PayFacto conformément à la
                        présente politique de confidentialité<span style="color: #535353">.</span>
                    </p>

                    <p>
                        <strong><span style="color: #535353">4.&nbsp;</span>Types de renseignements recueillis</strong>
                    </p>

                    <p>Le type de renseignements qui peuvent être recueillis comprend, sans toutefois s’y limiter :</p>

                    <ul>
                        <li>Coordonnées telles que votre nom, votre adresse résidentielle, votre numéro de téléphone, votre adresse e-mail – nous les utilisons pour communiquer avec vous.</li>
                        <li>
                            Les informations d’identité telles que votre date de naissance, votre sexe, votre numéro d’assurance sociale, d’autres pièces d’identité gouvernementales (telles que les informations de permis de conduire ou de passeport),
                            votre emploi et des informations sur d’autres comptes financiers (s’ils sont utilisés pour vérifier votre identité).
                        </li>
                        <li>Informations sur votre emploi, revenu et actifs actuels et passés – nous les utilisons pour évaluer votre aptitude en tant que client et votre admissibilité aux produits et services liés au crédit.</li>
                        <li>
                            Informations sur la façon dont vous utilisez nos produits et services – nous les utilisons pour vous fournir ces produits et services, pour nous protéger et protéger votre compte (en repérant les activités inhabituelles)
                            et pour vous informer des changements qui auront un impact sur vos produits et services. Nous les utilisons également pour vous fournir des produits et services personnalisés, pour mieux comprendre vos besoins et vos
                            préférences (y compris grâce à l’analyse de données), pour personnaliser votre expérience sur nos sites Web et applications mobiles, et pour vous fournir des communications et des offres personnalisées.
                        </li>
                        <li>Renseignements sur une autre personne – si vous avez un compte conjoint ou un tuteur légal ou un représentant personnel, nous recueillerons également des renseignements personnels sur ces personnes.</li>
                        <li>D’autres informations que vous fournissez volontairement à nous, à nos employés, à nos fournisseurs de services ou à nos sous-traitants.</li>
                    </ul>

                    <p>Les renseignements seront recueillis par des moyens justes et légaux. Nous ne collectons pas davantage d’informations que ce dont nous avons besoin<span style="color: #535353">.</span></p>

                    <p>
                        <strong><span style="color: #535353">5. </span></strong><b><span style="color: #535353">Pourquoi nous recueillons vos informations</span></b>
                    </p>

                    <p>Voici quelques exemples de la raison pour laquelle nous recueillons des renseignements personnels et de la façon dont nous les utilisons :</p>

                    <ul>
                        <li>pour vérifier votre identité, y compris pour respecter nos obligations en matière de « connaissance du client », de lutte contre le blanchiment d’argent et de sanctions;</li>
                        <li>pour évaluer et traiter votre demande de compte ou de limite de crédit, y compris pour déterminer votre admissibilité;</li>
                        <li>pour obtenir votre dossier de crédit;</li>
                        <li>pour communiquer avec vous au sujet de vos prêts, titres ou dépôts;</li>
                        <li>pour apporter les modifications que vous demandez à votre compte;</li>
                        <li>pour traiter et suivre les transactions et vous faire rapport;</li>
                        <li>pour détecter et prévenir le vol, la fraude et l’erreur, et pour vous protéger, nous et d’autres personnes contre le vol, la fraude et l’erreur;</li>
                        <li>pour analyser l’information afin de déterminer que les services pertinents vous sont offerts;</li>
                        <li>pour vous fournir des produits et services, ou des renseignements sur des produits et services, qui ont été demandés par vous et/ou qui, selon PayFacto, pourraient vous intéresser et vous bénéficier;</li>
                        <li>pour vous informer des nouvelles initiatives commerciales, y compris en vous contactant pour obtenir vos points de vue et pour vous encourager à exprimer vos points de vue à leur sujet;</li>
                        <li>pour vous offrir la possibilité de participer à des concours, des cadeaux ou d’autres promotions;</li>
                        <li>pour mener des recherches et générer des statistiques liées à nos activités, produits et services;</li>
                        <li>
                            à des fins commerciales, telles que l’analyse de données, les audits, le développement de nouveaux produits, l’amélioration, l’amélioration ou la modification de nos services, l’identification des tendances d’utilisation,
                            la détermination de l’efficacité de nos campagnes promotionnelles, et l’exploitation et l’expansion de nos activités commerciales;
                        </li>
                        <li>pour vous aider à gérer et à évaluer nos risques, nos opérations et nos relations avec vous;</li>
                        <li>pour obtenir et maintenir une couverture d’assurance;</li>
                        <li>pour se conformer aux exigences légales et gouvernementales;</li>
                        <li>pour remplir toute autre fin pour laquelle vous le fournissez ou avec votre consentement; et</li>
                        <li>
                            si vous détenez un produit qui génère un revenu et que nous avons votre NAS, nous pouvons l’utiliser à des fins de déclaration fiscale et partager des renseignements sur le revenu avec l’Agence du revenu du Canada. Avec
                            notre consentement, nous pouvons également utiliser votre NAS pour faire correspondre vos renseignements personnels et les renseignements de votre agence d’évaluation du crédit, ou comme numéro d’identification interne
                            pour identifier avec précision les clients ayant le même nom ou un nom similaire.
                        </li>
                    </ul>

                    <p><span style="color: #535353">Nous obtiendrons votre consentement avant de procéder à toute autre fin non identifiée dans la présente politique de confidentialité.</span></p>

                    <p>
                        <strong><span style="color: #535353">6. </span></strong><b><span style="color: #535353">Comment nous recueillons vos informations</span></b>
                    </p>

                    <p>
                        Les renseignements personnels peuvent être recueillis de plusieurs façons, y compris : par l’entremise de notre site Web, en personne, par la poste, par courriel, à partir de nos applications ou d’autres logiciels, et auprès
                        de tiers que vous avez autorisés à nous divulguer des renseignements personnels.
                    </p>

                    <p>Si vous fournissez des informations sur un tiers ou autorisez un tiers à le faire, nous supposerons que vous avez pris les mesures appropriées pour obtenir le consentement éclairé<span style="color: #535353">.</span></p>

                    <p>
                        <span style="color: #535353"><strong>7.</strong> </span><b><span style="color: #535353">Avec qui nous partageons vos informations</span></b>
                    </p>

                    <p>PayFacto peut partager vos informations avec des tiers. &nbsp;PayFacto le fera pour soutenir la fourniture de nos services, l’administration de notre entreprise, et à toute autre fin énoncée ci-dessous, y compris:</p>

                    <ul>
                        <li>Avec les sociétés affiliées: Pour nous aider à vous offrir les meilleurs services, nous pouvons partager vos informations personnelles avec nos autres sociétés et sociétés affiliées respectives.</li>
                        <li>
                            Avec nos employés : Dans l’exercice de leurs fonctions, nos employés autorisés peuvent avoir accès à des renseignements personnels et à d’autres renseignements confidentiels.&nbsp; Les employés ne peuvent accéder qu’aux
                            renseignements personnels strictement nécessaires à l’exercice de leurs fonctions. &nbsp;Tous les employés qui sont susceptibles d’avoir accès à des renseignements personnels doivent signer un code de conduite en vertu
                            duquel ils s’engagent à en préserver la confidentialité.
                        </li>
                        <li>
                            Avec des fournisseurs de produits et de services tiers : Nous pouvons divulguer des renseignements personnels à certains fournisseurs de produits et de services tiers dont nous avons retenu les services, pour vous aider à
                            vous fournir des produits et services, ou pour effectuer certains services spécialisés pour nous aider dans notre entreprise.
                            <ul>
                                <li>
                                    Nous ne divulguons à ces fournisseurs que les informations spécifiques dont ils ont besoin pour fournir les services.&nbsp;Avant de leur communiquer des renseignements personnels, chacun de nos fournisseurs doit
                                    s’engager à utiliser les renseignements uniquement pour exécuter les services qu’ils ont été retenus pour fournir, doit accepter de protéger et de respecter la confidentialité des informations, et doit accepter des
                                    conditions contractuelles qui sont conformes à notre politique de confidentialité. Les fournisseurs tiers doivent également se conformer aux lois canadiennes applicables en matière de protection de la vie privée.
                                </li>
                                <li>
                                    Lorsque nous faisons appel à ces fournisseurs, nous pouvons traiter, stocker et transférer vos renseignements personnels dans et vers un pays étranger, avec différentes lois sur la protection de la vie privée qui
                                    peuvent ou non être aussi complètes que la loi canadienne. Dans ces circonstances, les gouvernements, les tribunaux, les organismes d’application de la loi ou les organismes de réglementation de ce pays peuvent
                                    être en mesure d’obtenir l’accès à vos renseignements personnels par le biais des lois du pays étranger. Chaque fois que nous engageons un fournisseur de services dans un autre pays, nous exigeons qu’il se conforme
                                    à notre politique de confidentialité et aux lois canadiennes applicables en matière de protection de la vie privée.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Transferts d’entreprises : Au fur et à mesure que nous continuons de croître, nous pouvons prendre de l’expansion ou vendre nos entreprises ou une partie de celles-ci.&nbsp;Dans un tel cas, les informations personnelles
                            que nous détenons peuvent faire partie des actifs transférés.
                        </li>
                        <li>
                            Nous pouvons également divulguer des renseignements personnels dans des situations où nous sommes légalement tenus ou autorisés à le faire. Ces situations peuvent inclure des enquêtes criminelles, des exigences de
                            déclaration fiscale du gouvernement, des ordonnances judiciaires ou des cas où nous croyons que les droits et la sécurité d’autrui peuvent être menacés. Un tribunal peut nous ordonner de divulguer des renseignements
                            personnels à un tiers ou au tribunal, ou de conserver des renseignements personnels en attendant l’issue d’une audience juridique. Nous pouvons également être légalement tenus de divulguer des informations dans le cadre du
                            recouvrement ou du remboursement d’une dette, pour aider à la prévention de la fraude ou d’autres activités criminelles, lorsque nous obtenons des conseils juridiques d’un avocat, ou dans une urgence qui affecte la santé
                            ou la sécurité d’une personne.
                        </li>
                    </ul>

                    <p>
                        Dans les cas décrits ci-dessus, nous pouvons être tenus de partager vos informations personnelles avec ou sans votre consentement. Dans des circonstances limitées, il peut nous être légalement interdit de vous informer que vos
                        informations ont été partagées de cette manière.
                    </p>

                    <p>
                        <span style="color: #535353"
                            >Si vous pensez qu’un tiers nous a divulgué vos renseignements personnels de manière inappropriée, veuillez communiquer directement avec ce tiers. Si le tiers ne répond pas suffisamment à vos demandes, veuillez-nous en
                            informer immédiatement.</span
                        >
                    </p>

                    <p><strong>8. Exactitude</strong></p>

                    <p>
                        Nous faisons tous les efforts raisonnables pour garder vos renseignements personnels aussi exacts, complets et à jour que nécessaire. Nous nous encourageons à vérifier l’exactitude et l’exhaustivité de vos renseignements
                        personnels dans nos dossiers.
                    </p>

                    <p>
                        Malgré nos efforts, des erreurs se produisent parfois. Si vous identifiez des informations incorrectes ou obsolètes dans votre dossier, nous remédierons à de telles erreurs en temps opportun. Dans le cas où des informations
                        inexactes sont envoyées par erreur à un tiers, nous communiquerons les modifications pertinentes au tiers le cas échéant.
                    </p>

                    <p><strong>9. Protection de vos renseignements personnels</strong></p>

                    <p>Nos employés et sous-traitants comprennent l’importance de garder vos informations privées. On s’attend à ce que tous les employés et entrepreneurs maintiennent la confidentialité des renseignements personnels.</p>

                    <p>
                        Pour nous assurer que vos renseignements personnels sont protégés, nous utilisons des mesures de sécurité qui sont conformes aux normes de l’industrie. Nous conservons les fichiers électroniques dans un environnement sécurisé
                        avec un accès et une utilisation restreinte.
                    </p>

                    <p>
                        Malgré toutes les mesures de sécurité susmentionnées, le risque d’intrusion malveillante demeure une réalité technologique à laquelle toute entreprise doit faire face. Nous encourageons toutes les personnes à ne fournir que
                        les informations qu’elles sont à l’aise de fournir à un tiers, à surveiller les communications suspectes et à nous signaler toute activité suspecte dès que possible. En tant que communauté, nous pouvons tous aider à assurer sa
                        sécurité.
                    </p>

                    <p><strong>10. Retrait</strong></p>

                    <p>Vous pouvez choisir de restreindre la collecte ou l’utilisation de vos renseignements personnels des façons suivantes :</p>

                    <ul>
                        <li>Vous pouvez nous écrire ou nous envoyer un courriel en utilisant les coordonnées ci-dessous pour retirer votre consentement à ce que nous utilisions vos informations personnelles à des fins de marketing direct.</li>
                        <li>Si vous recevez un e-mail commercial de notre part, vous pouvez également vous désabonner en utilisant l’outil de désabonnement que nous fournissons dans ces messages.</li>
                    </ul>

                    <p>
                        Veuillez noter que si vous cherchez à retirer votre consentement à la collecte et au traitement des informations que nous devons traiter pour fournir nos services, nous pourrions ne pas être en mesure de vous fournir ces
                        services. Par exemple, vous ne pouvez pas retirer votre consentement à la collecte, à l’utilisation et à la communication continues des renseignements personnels nécessaires à l’administration d’un produit de prêt que vous
                        avez acheté. De même, vous ne pouvez pas retirer votre consentement à la collecte, à l’utilisation ou à la divulgation des renseignements dont nous avons besoin pour réaliser des fins réglementaires.
                    </p>

                    <p><strong>11. Témoins de connexion</strong></p>

                    <p>
                        Nous pouvons également collecter des informations par le biais de témoins de connexions, aussi connus sous le nom de «&nbsp;cookies&nbsp;» sur notre site Web. Les cookies sont de petits fichiers texte à l’intérieur de votre
                        ordinateur qui contiennent un identifiant unique, ce qui nous permet de vous reconnaître lorsque vous visitez à nouveau notre site Web. Cela nous aide à adapter notre contenu pour répondre à vos besoins et à des fins
                        publicitaires. Vous pouvez refuser d’accepter les cookies en activant le paramètre de votre navigateur qui vous permet de refuser l’installation de cookies. Cependant, si vous sélectionnez ce paramètre, vous ne pourrez
                        peut-être pas accéder à certaines parties de notre site Web. En acceptant les cookies et en continuant à visiter ou à utiliser notre site Web, vous acceptez l’utilisation de cookies et de technologies similaires aux fins que
                        nous décrivons dans la présente politique de confidentialité.
                    </p>

                    <p>
                        Les cookies peuvent être utilisés pour : (i) vous reconnaître lorsque vous visitez le site Web, vous souvenir de vos préférences et vous offrir une expérience personnalisée conforme à vos paramètres, (ii) rendre vos
                        interactions avec le site Web plus rapides et plus sécurisées, et (iii) vous apporter de la publicité et des expériences personnalisées grâce à la personnalisation. De plus, les cookies sont utilisés pour l’authentification,
                        la sécurité et la recherche.
                    </p>

                    <p>
                        Pour en savoir plus sur les cookies, y compris comment voir quels cookies ont été définis et comment les gérer et les supprimer, visitez <a href="http://www.wikipedia.org/">wikipedia.org</a>,
                        <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a> ou <a href="http://www.aboutcookies.org/">aboutcookies.org</a>.
                    </p>

                    <p><strong>12. Médias sociaux</strong></p>

                    <p>
                        Si vous publiez un avis, faites un commentaire ou soumettez des informations personnelles sur un forum public tel que des comptes de médias sociaux ou des forums publics sur notre site Web ou d’autres applications, vos
                        communications peuvent être visibles par le public.
                    </p>

                    <p><strong>13. Mise à jour de cette politique de confidentialité</strong></p>

                    <p>
                        PayFacto s’efforce d’évoluer pour répondre aux besoins et aux attentes de nos clients, ce qui signifie que nos services, la façon dont nous offrons nos produits et la façon dont nous communiquons l’information peuvent changer.
                        Dans cet esprit, nous examinons nos pratiques de confidentialité de temps à autre, et nos pratiques de traitement de l’information peuvent modifiées. Si PayFacto apporte un changement important à cette politique de
                        confidentialité, nous fournirons une version révisée sur notre site Web et indiquerons la date à laquelle les modifications ont été apportées. Les modifications prendront effet lors de la publication sur notre site Web de ces
                        modifications. Vous pouvez déterminer quand cette politique de confidentialité a été mise à jour pour la dernière fois en vous référant à la date trouvée au bas de cette politique de confidentialité. Si, à tout moment, vous
                        n’êtes pas d’accord avec les termes de cette politique de confidentialité, vous ne devez pas utiliser la&nbsp; plateforme, le site Web ou les services PayFacto.
                    </p>

                    <p><strong>14. Site Web et applications régis par la présente politique de confidentialité</strong></p>

                    <p>
                        Notre site Web et toutes nos applications disponibles sur notre site Web ou sur des plateformes tierces sont régis par les dispositions et les pratiques énoncées dans la présente politique de confidentialité. Notre site Web et
                        ces applications peuvent contenir des liens vers des sites ou des applications tiers qui ne sont pas régis par la présente politique de confidentialité. Bien que nous nous efforcions de créer uniquement des liens vers des
                        sites ou des applications qui partagent notre engagement envers votre vie privée, veuillez noter que cette politique de confidentialité ne s’appliquera plus une fois que vous quitterez notre site Web ou de telles applications,
                        et que nous ne sommes pas responsables des pratiques de confidentialité des sites ou applications tiers. Nous vous suggérons donc d’examiner de près les politiques de confidentialité respectives des sites et applications tiers
                        pour savoir comment ils collectent, utilisent et divulguent vos informations personnelles.
                    </p>

                    <p><strong>15. Loi applicable</strong></p>

                    <p>La présente politique de confidentialité et toutes les questions connexes doivent être interprétées et interprétées conformément aux lois de la province de Québec et aux lois fédérales applicables du Canada.</p>

                    <p><strong>16. Renseignements personnels à l’extérieur du Canada</strong></p>

                    <p>
                        Les renseignements personnels fournis à nos fournisseurs de services peuvent être stockés à l’extérieur du Québec et à l’extérieur du Canada. Vous reconnaissez et acceptez que, par conséquent, vos informations personnelles
                        peuvent être traitées, utilisées, stockées ou consultées dans d’autres juridictions et peuvent être soumises aux lois de ces juridictions. Par exemple, les renseignements peuvent être divulgués en réponse à des demandes
                        valides ou à des demandes d’autorités gouvernementales, de tribunaux ou d’organismes d’application de la loi d’autres pays.
                    </p>

                    <p><strong>17. Répondre à vos demandes de renseignements et à vos préoccupations</strong></p>

                    <p>Nous sommes heureux de vous fournir une copie de cette politique de confidentialité et de discuter de tout contenu avec vous.</p>

                    <p>
                        Nous avons désigné notre vice-président des affaires juridiques comme agent de la protection de la vie privée qui est responsable du maintien et de la mise en œuvre de notre politique de confidentialité. Veuillez adresser
                        toutes les questions ou demandes de renseignements concernant cette politique de confidentialité à:
                    </p>

                    <p>À l’attention de la Responsable de la protection des renseignements personnels</p>

                    <p>Courriel : <a href="mailto:legal@payfacto.com">legal@payfacto.com</a></p>

                    <p>
                        Paiements PayFacto Inc.<br />
                        1, Place du Commerce #402<br />
                        Montréal (Québec) H3E 1A2<br />
                        Téléphone (sans frais) : 877 341-8293
                    </p>

                    <p>___________________________________</p>

                    <p>Dernière révision : novembre 2022</p>
                </div>
            </slot>
        </LegalInformation>
    </Dialog>
</template>

<style lang="scss" scoped></style>

<i18n lang="yaml">
en:
    title: Privacy Policy
fr:
    title: Politique de confidentialité
</i18n>

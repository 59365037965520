<script setup lang="ts">

import { useVersionCheck } from "~/composables/useVersionCheck";
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted } from "vue";
import { env } from "~/env";

const { t } = useI18n();
const { refreshNow, refreshLater, startChecking, stopChecking, isOutdatedVersion } = useVersionCheck(env.VERSION_CHECK_DELAY);

onMounted(() => {
    startChecking();
});

onUnmounted(() => {
    stopChecking();
});

</script>

<template>
    <Dialog
        v-model:visible="isOutdatedVersion"
        class="new-version-dialog"
        :closable="false"
        :header="t('New Version Available')"
        :modal="false"
        position="bottom"
        :show-header="false"
        style="width: 100%;"
    >
        <div class="container justify-between content-between flex flex-column xl:flex-row align-items-center justify-content-between">
            <div class="flex flex-column xl:flex-row align-items-center justify-content-center text-sm lg:text-lg text-center m-0">
                <div class="icon text-center flex align-items-center justify-content-center ">
                    <svg height="16" viewBox="0 0 12.505 16" width="12.505" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <linearGradient id="linear-gradient" gradientUnits="objectBoundingBox" x2="1" y2="1">
                                <stop offset="0" stop-color="#006cc5"/>
                                <stop offset="1" stop-color="var(--primary-color)"/>
                            </linearGradient>
                        </defs>
                        <path id="IMG_Immportant-News" d="M180.626-846.34a.6.6,0,0,1-.445-.18.605.605,0,0,1-.18-.446.6.6,0,0,1,.18-.445.605.605,0,0,1,.445-.18h.882v-6.221a4.614,4.614,0,0,1,1.038-2.974,4.6,4.6,0,0,1,2.665-1.653v-.519a1,1,0,0,1,.3-.738,1,1,0,0,1,.737-.3,1.007,1.007,0,0,1,.738.3,1,1,0,0,1,.3.738v.519a4.6,4.6,0,0,1,2.665,1.653A4.614,4.614,0,0,1,191-853.811v6.221h.882a.6.6,0,0,1,.445.18.606.606,0,0,1,.18.446.6.6,0,0,1-.18.445.605.605,0,0,1-.445.18ZM186.254-852.448Zm0,8.449a1.448,1.448,0,0,1-1.064-.443,1.453,1.453,0,0,1-.442-1.064h3.014a1.45,1.45,0,0,1-.443,1.065A1.455,1.455,0,0,1,186.252-844Zm-3.494-3.591h6.99v-6.221a3.367,3.367,0,0,0-1.024-2.471,3.367,3.367,0,0,0-2.471-1.024,3.367,3.367,0,0,0-2.471,1.024,3.367,3.367,0,0,0-1.024,2.471Zm3.5-3.736a.6.6,0,0,0,.445-.18.605.605,0,0,0,.18-.445v-2.838a.6.6,0,0,0-.18-.445.605.605,0,0,0-.446-.18.6.6,0,0,0-.445.18.605.605,0,0,0-.18.445v2.838a.6.6,0,0,0,.18.445A.605.605,0,0,0,186.254-851.326Zm0,2.421a.652.652,0,0,0,.48-.194.652.652,0,0,0,.194-.48.652.652,0,0,0-.194-.48.652.652,0,0,0-.48-.194.652.652,0,0,0-.48.194.652.652,0,0,0-.194.48.652.652,0,0,0,.194.48A.652.652,0,0,0,186.254-848.9Z" fill="url(#linear-gradient)" transform="translate(-180.001 859.999)"/>
                    </svg>
                </div>
                <div class="flex flex-column align-items-center xl:align-items-start">
                    <h6 class="mt-2 xl:mt-0text-center xl:text-left">{{ t('New Veloce version available!') }}</h6>
                    <h5 class="text-center xl:text-left mb-4 xl:mb-0">
                        {{
                            t(
                                "We noticed you are currently using a previous version of Veloce Analytics. Refresh your page to access new features and improvements.",
                            )
                        }}
                    </h5>
                </div>
            </div>
            <div class="flex flex-row flex-grow-0">
                <Button class="xl:ml-4" @click="refreshNow">{{ t('Refresh') }}</Button>
                <Button class="md:h-auto md:mt-0 p-button-text" @click="refreshLater">{{ t('Continue') }}</Button>
            </div>
        </div>
    </Dialog>
</template>

<style lang="scss">
#newVersion {
    z-index: 100000;
    flex: 0 0 240px;
    display: flex;
    width: 240px !important;
    border-radius: 16px !important;
    box-shadow: 0px 4px 12px #00000029 !important;
    h6 {
        font: normal normal bold 14px/19px Roboto;
        color: var(--gray-800)
    }
    p {
        font: normal normal normal 12px/16px Roboto !important;
        color: var(--gray-700);
    }
    .p-overlaypanel-content {
        padding: 4px !important;
    }
}

.new-version-dialog {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 32px 0;
    background: var(--surface-a) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000029;

    .container {
        width: 100% !important;
        max-width: 1140px;
        margin: 0 auto;
    }

    h6 {
        text-align: left;
        font: normal normal bold 12px/16px Roboto;
        letter-spacing: 0px;
        color: var(--primary-color);
        margin: 0;
    }

    h5 {
        text-align: left;
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: -0.02px;
        margin: 0;
    }
    .icon {
        width: 32px; height: 32px;
        background: var(--gray-200) 0% 0% no-repeat padding-box;
        border-radius: 50%;
        margin-right: 16px;
    }
    .p-dialog-title {
        font: normal normal bold 14px/19px Roboto !important;
    }

    .p-dialog-content {
        background-color: transparent !important;
        padding: 0!important;
    }
}

.p-button:focus {
    box-shadow: none !important;
}

</style>

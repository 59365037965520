import { createRouter, createWebHistory } from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from '~pages'

const routes = setupLayouts(generatedRoutes)

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.beforeEach(async (to) => {
    console.debug('Route', to.fullPath);
    const publicPages = ['/login', '/forgotpassword', '/contact', '/reset-password']
    const authRequired = !publicPages.includes(to.path)
    const auth = useAuthStore()

    if (to.path === '/') {
        return '/login'
    }

    if (!authRequired && auth.user?.id) {

        return auth.returnUrl || '/app/dashboard'
    }

    if (authRequired && !auth.user?.id) {
        auth.returnUrl = to.fullPath

        return '/login'
    }
})

export default router

import { createFetch } from '@vueuse/core'
import { DateTime } from 'luxon'
import { env } from '../env'

function saveAs(fileName, data) {
    const xlsxUrl = window.URL.createObjectURL(data)
    const a = document.createElement('a')
    a.href = xlsxUrl
    a.download = fileName
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()
}

export const useFetch = createFetch({
    baseUrl: env.BACKEND_URL,
//    combination: 'overwrite',
    options: {
        async beforeFetch({ options, url, query }: any) {

            const currentUser = JSON.parse(window.localStorage.getItem('user') || '{}')
            const urlObj = new URL(url)

            options.headers.Authorization = `Bearer ${currentUser && currentUser.token}`

            switch (urlObj.searchParams.get('downloadFileType')) {
                case 'pdf':
                    options.headers['Content-Type'] = 'application/pdf'
                    options.headers['Velcloud-ClientTime'] = DateTime.now().toISO();
                    break
                case 'xlsx':
                    options.headers['Content-Type'] =
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    options.headers['Velcloud-ClientTime'] = DateTime.now().toISO();
                    break
            }

            const parsedUrl = new URL(url)

            if (parsedUrl.searchParams.has('locationIDs')) {
                parsedUrl.searchParams.delete('groupIDs')
            }

            if (parsedUrl.searchParams.get('sort') === '') {
                parsedUrl.searchParams.delete('sort')
            }
            if (parsedUrl.searchParams.get('order') === '') {
                parsedUrl.searchParams.delete('order')
            }

            const locale = localStorage.getItem('locale') || 'en';
            parsedUrl.searchParams.set('lang', locale)

            parsedUrl.searchParams.set('lang', locale)

            url = parsedUrl.toString()

            return { options, url, query }
        },
        async onFetchError({ error, response, data }) {
            const urlObj = new URL(response.url)

            if(urlObj.searchParams.get('downloadFileName')) {
                const errorStore = useErrorStore();

                if (data instanceof Blob) {
                    const body = await data.text();

                    if(body === 'Too many invoices') {
                        errorStore.setError(body);
                        return { error, response, data }
                    }
                }

                errorStore.setError('An error has occurred, please try again later.');
                return { error, response, data }
            }
        },
        async afterFetch({ data, response }) {
            const urlObj = new URL(response.url)
            if (data instanceof Blob) {
                const downloadFileName = urlObj.searchParams.get('downloadFileName') || new Date().getTime().toString()
                const downloadFileType = urlObj.searchParams.get('downloadFileType')
                const dateTimeString = DateTime.fromISO(new Date().toISOString()).toFormat('yLLddhhmmss')

                saveAs(
                    `${downloadFileName}-${dateTimeString}.${downloadFileType}`,
                    downloadFileType === 'json'
                        ? new Blob([JSON.stringify(JSON.parse(await data.text()), null, 4)])
                        : data
                )
            }

            return { data, response }
        }
    },
    fetchOptions: {
        mode: 'cors',
    }
})

<template>
    <div :class="containerClass" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="p-toast-message-content py-3 align-items-center justify-content-center" :class="message.contentStyleClass">
            <template v-if="!template">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path
                        id="IMG_Checkmark"
                        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0m5.553,10.191-5.865,5.89a1.628,1.628,0,0,1-2.331,0L6.491,13.217A1.517,1.517,0,0,1,6,12.087a1.67,1.67,0,0,1,2.822-1.2l1.7,1.7,4.643-4.667a1.657,1.657,0,0,1,2.36,0A1.692,1.692,0,0,1,18,9.051a1.56,1.56,0,0,1-.447,1.14"
                        fill="#fff"
                    />
                </svg>

                <div class="p-toast-message-text">
                    <span v-if="message.summary" class="p-toast-summary">{{ message.summary }}</span>
                    <div class="p-toast-detail mt-0">{{ message.detail }}</div>
                </div>
            </template>
            <component v-else :is="template" :message="message"></component>
            <div v-if="message.closable !== false">
                <div id="countdown">
                    <div id="countdown-number"></div>
                    <svg>
                        <circle :style="'--duration: ' + message.life + 'ms'" r="14" cx="16" cy="16"></circle>
                    </svg>
                    <button v-ripple class="p-toast-icon-close p-link" @click="onCloseClick" type="button">
                        <span :class="['p-toast-icon-close-icon', closeIcon]" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Ripple from 'primevue/ripple';

export default {
    name: 'ToastMessage',
    emits: ['close'],
    props: {
        message: null,
        template: null,
        closeIcon: null,
        infoIcon: null,
        warnIcon: null,
        errorIcon: null,
        successIcon: null,
    },
    closeTimeout: null,
    mounted() {
        if (this.message.life) {
            this.closeTimeout = setTimeout(() => {
                this.close();
            }, this.message.life);
        }
    },
    beforeUnmount() {
        this.clearCloseTimeout();
    },
    methods: {
        close() {
            this.$emit('close', this.message);
        },
        onCloseClick() {
            this.clearCloseTimeout();
            this.close();
        },
        clearCloseTimeout() {
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
                this.closeTimeout = null;
            }
        },
    },
    computed: {
        containerClass() {
            return [
                'p-toast-message',
                this.message.styleClass,
                {
                    'p-toast-message-info': this.message.severity === 'info',
                    'p-toast-message-warn': this.message.severity === 'warn',
                    'p-toast-message-error': this.message.severity === 'error',
                    'p-toast-message-success': this.message.severity === 'success',
                },
            ];
        },
        iconClass() {
            return [
                'p-toast-message-icon',
                {
                    [this.infoIcon]: this.message.severity === 'info',
                    [this.warnIcon]: this.message.severity === 'warn',
                    [this.errorIcon]: this.message.severity === 'error',
                    [this.successIcon]: this.message.severity === 'success',
                },
            ];
        },
    },
    directives: {
        ripple: Ripple,
    },
};
</script>
<style lang="scss" scoped>
.p-toast-message-success {
    background: #30d744 0% 0% no-repeat padding-box !important;
}

.p-toast-message-error {
    background: var(--red-400) 0% 0% no-repeat padding-box !important;
}

.p-toast-icon-close {
    color: #ffffff !important;
}

.p-toast-detail,
.p-toast-summary {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
}

.p-toast-message {
    box-shadow: 0px 4px 12px #00000029 !important;
}

#countdown {
    position: relative;
    width: 32px;
    height: 32px;

    text-align: center;

    .p-toast-icon-close {
        position: absolute;
        left: 2px;
        top: 2px;
    }

    #countdown-number {
        color: white;
        display: inline-block;
        line-height: 32px;
    }

    svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    svg circle {
        stroke-dasharray: 113px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 1px;
        stroke: white;
        fill: none;
        animation: countdown var(--duration) linear forwards;
    }
}

@keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }
    to {
        stroke-dashoffset: 113px;
    }
}
</style>

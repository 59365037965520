import { UserModule } from '~/types'
import { vAutoScrollRight } from '~/directives/auto-scroll-right'

export const install: UserModule = ({ router }) => {
    router.afterEach(() => {
        const wrapper = document.querySelector('.p-datatable-wrapper');
        if (wrapper) {
            wrapper.scrollTop = 0;
        }
    });
}

import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import Tooltip from 'primevue/tooltip'

import { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
    app.directive('styleclass', StyleClass)
    app.directive('ripple', Ripple)
    app.directive('tooltip', Tooltip)
}

<script setup>
import { useI18n } from 'vue-i18n';
import LegalInformation from 'pay-core/legalinformation';
import Dialog from 'pay-core/dialog';
import { computed } from 'vue';

const { t, locale } = useI18n();

const props = defineProps({
    /**
     * Text to display in the title
     */
    title: { type: String, default: 'title' },
    /**
     * State of the dialog
     */
    visible: { type: Boolean, default: true },
});

const emit = defineEmits([
    /**
     * Emitted when the value changes.
     * @param {Event} event - New value.
     */
    'update:visible',
]);

const isVisible = computed({
    get() {
        return props.visible;
    },
    set(value) {
        emit('update:visible', value);
    },
});
</script>
<template>
    <Dialog :header="t('title')" v-model:visible="isVisible" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '50vw', height: '80vh' }" :modal="true" class="pb-4">
        <LegalInformation :showTitle="false">
            <slot>
                <div class="px-6" v-if="locale === 'fr'">
                    <h3>ABONNEMENT AUX LICENCES PAYFACTO
                        CONDITIONS GÉNÉRALES</h3>
                    <p>
                        Les Modalités et Conditions ci-dessous pour une « Licence Veloce, Maître’D et/ou
                        iShopFood » (le « Produit »), peuvent être modifiés de temps à autre et sont jointes au
                        formulaire d’adhésion. Les Modalités et Conditions, ainsi que le Formulaire d’adhésion
                        sont appelés aux présentes le « Contrat ». Ce Contrat est un engagement liant
                        PayFacto Payments inc. (et, si le Produit comprend IshopFood - 9327-5329 Québec
                        inc., une filiale de Paiements PayFacto Inc. ) (« PayFacto ») et le Client (tel que décrit
                        dans le Formulaire d’adhésion), et n’est pas assujetti à l’émission de nouveaux bons de
                        commande, confirmations ou autres articles, sauf indication expresse aux présentes.
                    </p>
                    <p>
                        En payant les frais de licence du Produit, le Client reconnaît qu’il a lu, compris et accepté
                        le Contrat, et consent expressément à être lié par celui-ci.
                    </p>
                    <p>
                        Le Contrat représente l’intégralité de l’accord entre les parties et ne peut être remplacé
                        par aucun document ultérieur, sauf accord écrit d’un représentant dûment autorisé de
                        PayFacto. Les clients ayant des abonnements mensuels peuvent annuler ce Contrat
                        avec un préavis de 30 jours sans frais ni pénalité
                    </p>
                    <p>
                        Les produits/services auxquels le Client s’abonne sont énumérés au formulaire
                        d’adhésion aux licences de points de service. Ces produits/services seront mis à la
                        disposition du Client dès réception du paiement des frais de licence du Produit. Le Client
                        comprend et reconnaît que ce Formulaire d’adhésion ne peut être annulé que
                        conformément aux Modalités et Conditions énoncés dans les Modalités et Conditions
                        ci-dessous et que les frais ne sont pas remboursables.
                    </p>
                    <p>L’adresse d’expédition indiquée ci-dessus déterminera la juridiction fiscale appropriée
                        des produits et services achetés.</p>
                    <h5>MODALITÉS ET CONDITIONS DU CONTRAT DE SERVICE PAYFACTO POUR LE PRODUIT</h5>
                    <p>Ce Contrat est conclu entre PayFacto Payments Inc. (« PayFacto ») et la personne
                        physique ou morale (« Client ») utilisant le Produit via un abonnement payant. Veuillez
                        lire attentivement ce Contrat. En acceptant ces conditions ou en utilisant le Produit, selon
                        la première éventualité, le Client accepte d’être lié par les termes de ce Contrat.</p>
                    <h6>1. Abonnement au produit et délais d’intégration</h6>
                    <p>Le Client accepte par la présente une licence d’utilisation du Produit identifié sur le
                        Formulaire d’adhésion signé par le Client (le formulaire est disponible sur le site Web de
                        PayFacto et dans la documentation d’Abonnement (« Abonnement ») (c’est-à-dire,
                        devis ou facture). Les frais spécifiques payables à l’égard de ce Produit sont indiqués
                        dans l’Abonnement. Chaque Produit peut inclure des mises à jour, des services basés
                        sur le cloud, des services de support, des applications ou de la documentation, chacun
                        d’entre eux étant soumis aux Modalités et Conditions du présent Contrat, le cas échéant.
                        PayFacto peut ajouter, modifier ou interrompre tout composant du Produit à tout
                        moment, à condition que de tels changements n’augmentent pas de manière
                        significative les obligations financières du Client ou ne diminuent pas considérablement
                        les obligations de PayFacto envers le Client en vertu du présent Contrat. Le Client est
                        responsable de toutes les actions prises en vertu de ses informations d’identification de
                        compte de Produit, que ces actions soient prises par le Client, ses employés, ses soustraitants, ses sociétés affiliées ou tout autre tiers utilisant le Produit (« Utilisateurs »).
                        PayFacto n’est pas responsable de toute perte ou dommage lié à ou découlant de
                        l’utilisation non autorisée du Produit identifié dans le compte du Client.
                    </p>
                    <p>
                        L’intégration du Produit peut prendre jusqu’à quatre (4) semaines une fois le processus
                        d’abonnement terminé. Les applications mobiles peuvent être soumises à des retards
                        plus longs en fonction des processus d’approbation des plates-formes tierces
                        concernées.</p>
                    <h6>2. Droits de propriété intellectuelle et réserve de propriété</h6>
                    <p>PayFacto est le propriétaire et/ou le licencié autorisé de tous les droits de propriété
                        intellectuelle sur le Produit, y compris, sans s’y limiter, les documents écrits, logos, noms
                        et autres documents de support fournis en vertu du présent Contrat et avant l’exécution
                        du présent Contrat. Sous réserve des licences expressément accordées par PayFacto
                        aux présentes, les présentes conditions d’utilisation ne transfèrent pas de PayFacto au
                        Licencié tout intérêt dans le Produit, tous les droits, titres et intérêts qui restent
                        uniquement avec PayFacto ou ses concédants de licence. Toutes les dispositions du
                        présent Contrat par PayFacto au Licencié, y compris, sans s’y limiter, le Produit, sont
                        concédées sous licence et ne sont pas vendues. PayFacto n’a pas et n’a pas transféré
                        de droits de propriété sous quelque forme ou de quelque manière que ce soit au titulaire
                        de licence. PayFacto et ses concédants de licence sont propriétaires du Produit, qui
                        sont protégés par les lois canadiennes et les lois, traités et conventions internationaux
                        applicables concernant la propriété intellectuelle ou les droits de propriété, y compris les
                        secrets commerciaux. À compter de la date de réception ou d’accès, le licencié accepte
                        d’utiliser des moyens raisonnables pour protéger le(s) produit(s) contre l’utilisation, la
                        reproduction, la distribution ou la publication non autorisées. PayFacto et ses
                        concédants de licence se réservent tous les droits qui ne sont pas spécifiquement
                        accordés dans le présent Accord, y compris le droit de changer et d’améliorer le Produit.</p>
                    <h6>3. Octroi d’une licence pour le Produit</h6>
                    <p>Pour la durée de l’abonnement, PayFacto accorde au client une licence limitée, non
                        exclusive, non transférable et révocable qui ne permet pas d’accorder une sous-licence.
                        Le Client reconnaît que tous les droits, titres et intérêts relatifs à tous les droits de
                        propriété intellectuelle sur le Produit, ainsi qu’à toutes les modifications, extensions,
                        scripts et autres œuvres dérivées des Produits fournis ou développés par PayFacto,
                        sont la propriété exclusive de PayFacto ou de ses concédants de licence. Tous les droits
                        non accordés au Client dans le présent Contrat sont réservés par PayFacto.</p>
                    <h6>4. Restrictions relatives aux licences de mise en marché</h6>
                    <p>
                        Le Client et tous les Utilisateurs doivent s’abstenir de (et s’abstenir d’autoriser un tiers
                        à) :</p><p>
                    (i) décompiler, désassembler ou autrement désosser, ou tenter de reconstruire ou de
                    découvrir tout code source, idées sous-jacentes, algorithmes, formats de fichiers ou
                    interfaces de programmation ou d’interopérabilité de ou utilisés par les Produits, par
                    quelque moyen que ce soit; (ii) distribuer des virus ou d’autres codes informatiques
                    nuisibles ou malveillants via ou dans le Produit; (iii) s’engager dans toute conduite qui
                    perturbe ou entrave l’utilisation et la jouissance du Produit par un tiers; (iv) supprimer
                    toute identification de produit, droit d’auteur ou autres avis du Produit; (v) vendre, louer,
                    prêter, céder, sous-licencier, accorder l’accès ou autrement transférer ou divulguer le
                    Produit en tout ou en partie à un tiers; (vi) utiliser le Produit à des fins de partage du
                    temps, de bureau de service ou d’hébergement, ou autrement utiliser, revendre, souslicencier, distribuer ou transférer, ou permettre à d’autres d’utiliser le Produit à ou au
                    profit de tiers; (vii) modifier ou incorporer le Produit dans ou avec d’autres logiciels ou
                    créer une œuvre dérivée de toute partie du Produit; (viii) utiliser la sortie ou d’autres
                    informations générées par le Produit à des fins autres que celles envisagées par le
                    présent Contrat; (ix) utiliser le Produit pour toute utilisation autre que l’utilisation
                    commerciale interne du Client; (x) utiliser des versions modifiées non autorisées du
                    Produit, y compris, sans s’y limiter, dans le but de créer un produit ou un service similaire
                    ou concurrentiel ou dans le but d’obtenir un accès non autorisé au Produit; (xi) utiliser le
                    Produit d’une manière contraire aux lois locales, étatiques / provinciales, fédérales et
                    étrangères applicables, y compris, sans limitation, celles relatives à la confidentialité, à
                    la protection des données, aux communications électroniques et à la législation antispam. PayFacto conserve tous les titres de propriété et, sauf sous licence expresse aux
                    présentes, tous les droits sur le Produit, toutes les copies, dérivés et améliorations
                    apportées au Produit et à toute la documentation et au matériel connexes, ou (x) vendre,
                    louer, concéder en sous-licence, prêter, céder, transférer, traduire, exporter ou partager
                    le temps du Produit.
                    </p>
                    <h6>5. Durée du Contrat</h6>
                    <p>L’Abonnement commence à la signature du présent Contrat (« Date d’entrée en
                        vigueur ») et le Produit sera mis à la disposition du Client dès réception par PayFacto
                        du premier paiement prévu dans le Formulaire d’adhésion. Le Contrat a une durée
                        initiale de 12 mois et sera renouvelé automatiquement sur une base mensuelle ou
                        annuelle, selon le cas, à moins que le client ou PayFacto ne fournisse un avis écrit de
                        30 jours à l’effet contraire.</p>
                    <h6>6. Paiement et frais</h6>
                    <p>Le Client accepte de payer à PayFacto les frais annuels et/ou mensuels (« Frais »)
                        indiqués dans l’Abonnement, conformément à la date limite et à la devise stipulées dans
                        l’Abonnement. Le Client doit, en tout temps, s’assurer de conserver un mode de
                        paiement valide dans les dossiers de PayFacto pour payer tous les frais engagés et
                        récurrents et en cas de changement, le Client est responsable de mettre à jour les
                        informations fournies à l’Annexe A des présentes dans un délai raisonnable. Tous les
                        paiements effectués par le Client à PayFacto en vertu du présent Contrat ne sont pas
                        remboursables et seront effectués via le mode de paiement automatique spécifié par le
                        Client dans l’Abonnement. Tous les montants impayés dus par le Client en vertu du
                        présent Contrat porteront intérêt au taux de dix-huit pour cent (18%) par an, ou, s’il est
                        inférieur, au taux maximum autorisé par la loi. Dans le cas où un chèque, un paiement
                        préautorisé ou un retrait automatique n’a pas suffisamment de fonds, des frais de 50,00$
                        seront facturés au Client.</p><p>
                    Toutes les taxes fédérales, étatiques ou provinciales applicables, y compris celles sur
                    les biens et services ou les ventes, seront ajoutées aux frais dus en vertu du présent
                    contrat. PayFacto peut ajuster les frais indiqués dans la liste de prix à tout moment avec
                    un préavis écrit de 30 jours.</p><p>
                    PayFacto se réserve le droit d’interrompre la licence du produit si les paiements ne sont
                    pas effectués à temps.</p>
                    <h6>7. Informations confidentielles, Confidentialité et Stockage de données</h6>
                    <p>Les informations collectées par PayFacto seront utilisées pour traiter la demande
                        d’adhésion du Client et, si elles sont acceptées, pour fournir le produit au client. Les
                        informations fournies par le Client resteront confidentielles et stockées dans un
                        environnement sécurisé. Vos renseignements personnels peuvent être traités et
                        stockés aux États-Unis par un fournisseur de services tiers agissant au nom de
                        PayFacto et peuvent faire l’objet d’une divulgation aux organismes d’application de
                        la loi et de réglementation aux États-Unis.</p><p>
                    PayFacto utilise et protège les informations relatives au Client, y compris les
                    informations transmises via le Produit, conformément à la Politique de confidentialité de
                    PayFacto, disponible à www.payfacto.com (« Site »). Le Client accepte et consent à ce
                    que PayFacto utilise et partage des données non personnelles avec des tiers pour créer
                    des profils de données anonymes, fournir des informations marketing segmentées, créer
                    des rapports statistiques agrégés et améliorer les produits et services actuels et futurs
                    de PayFacto.</p><p>
                    PayFacto et le Client (et ses Utilisateurs) traiteront comme confidentiels tous les
                    renseignements reçus de l’autre partie en vertu du présent Contrat ou en lien avec celuici. Toutefois, chaque partie peut divulguer ces renseignements de façon confidentielle
                    à ses conseillers juridiques et financiers immédiats, comme l’exige le cours normal des
                    affaires. Nonobstant ce qui précède, les restrictions énoncées ci-dessus ne s’appliquent
                    pas à (i) les informations précédemment connues de la partie destinataire sans
                    référence aux informations confidentielles de la partie divulgatrice, (ii) les informations
                    qui sont ou deviennent publiquement connues sans acte fautif de la partie destinataire,
                    (iii) les informations qui sont développées indépendamment par l’une ou l’autre des
                    parties, ou (iv) les informations devant être divulguées en vertu de toute loi applicable.
                    Ce qui précède n’empêche pas PayFacto d’utiliser le nom et/ou la marque du Client
                    dans son matériel de marketing et de formation ou les données du Client sur une base
                    anonyme et compilée.</p>
                    <h6>8. Garanties et déclarations du Client</h6>
                    <p>
                        Le Client déclare et garantit qu’à compter de la Date d’entrée en vigueur et tout au long
                        de la durée de l’Abonnement, (i) le Client est dûment autorisé à conclure le présent
                        Contrat et le Client et tous les Utilisateurs sont dûment autorisés à utiliser les Produits,
                        (ii) le Client et tous les Utilisateurs sont et resteront en conformité avec toutes les lois et
                        réglementations applicables concernant leurs activités relatives au présent Contrat, y
                        compris, mais sans s’y limiter, les lois sur la protection de la vie privée; et (iii) si le Client
                        ou l’un des Utilisateurs importe des listes dans les Produits dans le but d’envoyer des
                        communications électroniques (par exemple, des courriels, des messages texte) aux
                        contacts contenus dans ces listes, ou de collecter autrement des adresses e-mail dans
                        le but d’envoyer des messages électroniques, le Client garantit que chaque personne
                        sur ladite liste a déjà choisi de recevoir des communications électroniques
                        promotionnelles du Client et que le contenu de ces communications envoyées par le
                        Client est conforme aux lois et règlements applicables. Le Client indemnise, défend et
                        tient PayFacto et sa direction, ses employés, ses agents et ses sociétés affiliées
                        indemnes de toutes pertes, dépenses, responsabilités, dommages et coûts, y compris,
                        mais sans s’y limiter, les frais juridiques raisonnables (collectivement, les « Coûts »)
                        dans la mesure où ces Coûts sont engagés en raison d’un défaut du Client ou de tout
                        Utilisateur de se conformer à toute représentation, garantie ou autre obligation énoncée
                        dans le présent Contrat. Le Client et chacun de ses Utilisateurs s’engagent à se
                        conformer à toutes les politiques de PayFacto et à toutes les lois, règles et
                        réglementations relatives à l’utilisation des Produits. </p><p>
                    Le Client s’engage à indemniser PayFacto et à les dégager de toute responsabilité à
                    l’égard des coûts, amendes ou dommages que PayFacto pourrait subir à la suite de
                    toute violation des dispositions du présent Contrat par le Client ou tout Utilisateur.
                    </p>
                    <h6>9. Transmission de matériel</h6>
                    <p>Le Client reconnaît que tout matériel qu’il transmet ou fournit à PayFacto, y compris,
                        mais sans s’y limiter, les questions, les commentaires, les suggestions, les idées, les
                        plans, les notes, les dessins, le matériel original ou créatif, ou toute autre information
                        relative à PayFacto ou aux Produits, qu’ils soient fournis sous forme de courriel, de
                        formulaires de rétroaction ou de tout autre format, appartient exclusivement à PayFacto
                        sans aucune obligation de reconnaître ou de rémunérer le Client.</p>
                    <h6>10. Services de tiers</h6>
                    <p>
                        Les « Services tiers » désignent les produits, applications, services, logiciels, produits,
                        réseaux, systèmes, répertoires, sites Web, bases de données et informations de tiers
                        auxquels un ou plusieurs Produits sont liés ou auxquels le Client peut se connecter ou
                        activer l’utilisation d’un ou de plusieurs Produits.</p><p>
                    Le Client peut décider d’activer, d’accéder ou d’utiliser des Services tiers. Le Client
                    reconnaît que l’accès et l’utilisation de ces Services tiers sont régis uniquement par les
                    conditions de ces Services tiers, et que PayFacto n’est pas responsable de leur contenu
                    ou de la manière dont ils traitent les données (y compris les données du Client).</p><p>
                    Toute utilisation des Services tiers par le Client se fera uniquement entre le Client et le
                    fournisseur tiers applicable. </p><p>
                    Le Client renonce irrévocablement à toute réclamation contre PayFacto à l’égard de ces
                    Services tiers. PayFacto n’est pas responsable de tout dommage ou perte causé ou
                    prétendument causé par ou en relation avec l’habilitation, l’accès ou l’utilisation par le
                    Client de ces Services tiers ou la confiance du Client dans les pratiques de
                    confidentialité, les processus de sécurité des données ou d’autres politiques de ces
                    Services tiers.
                    </p>
                    <h6>11. Activités de maintenance</h6>
                    <p>PayFacto peut avoir besoin d’effectuer des réparations ou des activités de maintenance
                        planifiées ou imprévues, ou de corriger ou de mettre à jour à distance le produit, ce qui
                        peut temporairement dégrader la qualité des services ou entraîner une panne partielle
                        ou complète du produit.</p><p>
                    PayFacto tentera d’effectuer des réparations ou de l’entretien pendant les périodes qui
                    causent le moins de perturbations. Le Client doit coopérer, si nécessaire, afin que ledit
                    travail puisse être effectué.</p><p>
                    PayFacto, ou le distributeur selon le cas, déploiera ses efforts commercialement
                    raisonnables pour fournir au Client un soutien à la clientèle, afin de l’aider à résoudre
                    des problèmes qui pourraient surgir.</p>
                    <p>PayFacto, ou le distributeur selon le cas, fournit un support pour le produit uniquement.
                        PayFacto, ou le distributeur selon le cas, décline toute responsabilité de quelque nature
                        que ce soit à l’égard de tout autre produit utilisé ou concédé sous licence par le Client,
                        même si en conjonction avec le Produit.</p>
                    <h6>12. Résiliation et suspension</h6>
                    <p>
                        <b>12.1. Résiliation pour défaut </b>En cas de défaut au présent Contrat par une partie, l’autre
                        partie peut résilier le présent Contrat en donnant à la partie en défaut un préavis écrit
                        de trente (30) jours indiquant en détail la nature du défaut et son intention de résilier le
                        Contrat à l’expiration du délai de trente (30) jours si la partie en défaut n’a pas résolu le
                        défaut. Dans un tel cas, le Contrat sera automatiquement résilié.</p><p>
                    Nonobstant ce qui précède, si le Client est (i) en défaut de respecter quelque disposition
                    importante du présent Contrat, y compris les restrictions de licence de la Section 4, les
                    conditions de paiement de la Section 6, les restrictions de confidentialité de la Section
                    7, ou les garanties et déclaration du Client à l’article 8, ou (ii) a fait une cession de biens,
                    devient insolvable ou est en faillite, PayFacto peut, à sa seule discrétion, suspendre ou
                    résilier immédiatement l’utilisation du Produit par le Client. Une telle suspension ou
                    résiliation ne dégagera pas le Client de ses obligations en vertu du présent Contrat, ni
                    ne donnera droit au Client à quelque remboursement des paiements effectués
                    précédemment. De plus, dans un tel cas, le Client et/ou tout Utilisateur doit comprendre
                    et accepter qu’ils ne disposeront d’aucun recours de quelque nature que ce soit contre
                    PayFacto.</p><p>
                    <b>12.2. Suspension temporaire</b> PayFacto peut suspendre temporairement l’accès du
                    Client au Produit immédiatement et sans préavis si PayFacto, à sa discrétion, estime
                    que : (i) une telle suspension est requise par la loi; (ii) le Client a manqué à l’une des
                    obligations énoncées dans le présent Contrat, telles qu’un paiement mensuel; ou (iii) il
                    y a un risque pour la sécurité ou la confidentialité du Client. Une suspension de l’accès
                    du Client aux Produits ne limitera ni n’annulera les droits de PayFacto de résilier le
                    présent Contrat ou de mettre fin à l’accès du Client au Produit</p><p>
                    <b>12.3. Effet de résiliation</b> Lors de la résiliation du présent Contrat, le Client et tous les
                    Utilisateurs doivent cesser d’utiliser le Produit. Nonobstant ce qui précède, la résiliation
                    du présent Contrat par PayFacto ne limitera ni ne mettra fin à l’obligation du Client de
                    payer tous les frais dus, ni n’empêchera PayFacto de poursuivre tout autre recours à
                    sa disposition, y compris la mesure injonctive. Les articles 4, 6-14 et 16-19 survivront à
                    la résiliation du présent contrat, ainsi qu’à toute autre disposition qui est censée, selon
                    leurs termes, survivre.</p><p>
                    <b>12.4. Données du Client</b> Le Client comprend et accepte que PayFacto n’a aucun
                    contrôle sur le contenu des données traitées par PayFacto au nom du Client. Le Client
                    est responsable de prendre ses propres mesures pour maintenir la sécurité, la
                    protection et la sauvegarde appropriées de ses données et logiciels, y compris
                    l’utilisation de pratiques de sauvegarde et d’archivage appropriées. Le Client reste
                    responsable du traitement et du traitement des avis concernant les données
                    personnelles de ses Clients.</p><p>
                    Le Client comprend et accepte que PayFacto n’est pas responsable de toute perte ou
                    corruption des données de son Client ou d’autres logiciels. Le Client reconnaît qu’à la
                    suite de la résiliation du compte du Client et/ou de l’utilisation du Produit, PayFacto peut
                    immédiatement désactiver le compte du Client et supprimer les données du Client. Le
                    Client reconnaît en outre que PayFacto n’est pas responsable envers le Client ou
                    envers un tiers de la résiliation de l’accès du Client au Produit ou de la suppression des
                    données du Client.
                    </p>
                    <h6>13. Limitation de responsabilité</h6>
                    <p>
                        PayFacto et ses partenaires s’engagent à fournir l’accès au Produit identifié dans
                        l’Abonnement, conformément aux Modalités et Conditions stipulés dans le présent
                        Contrat, tant que le Client paie les frais mensuels ou annuels (selon le cas). Cependant,
                        PayFacto sera libéré de sa responsabilité s’il y a : (i) toute violation ou appropriation
                        illicite ou délibérée par le Client des droits de propriété intellectuelle de PayFacto, de
                        ses partenaires ou fournisseurs, ou (ii) les obligations d’indemnisation du Client en vertu
                        du présent Contrat dans la mesure maximale permise par la loi. Aucune des parties ne
                        sera tenue responsable des dommages consécutifs, collatéraux, spéciaux ou punitifs,
                        ou de la perte de profits, de redevances ou de données, ou du coût de l’achat de biens
                        ou de services de substitution, que ce soit pour rupture de contrat, garantie,
                        responsabilité civile, recours légal ou toute obligation en découlant ou autrement, et
                        indépendamment du fait que chaque partie ait informé ou ait été informée de la
                        possibilité d’une telle perte ou d’un tel dommage. Le Client renonce par la présente à
                        toute réclamation selon laquelle ces exclusions le privent d’un recours adéquat. Les
                        parties reconnaissent que les dispositions du présent article répartissent équitablement
                        les risques en vertu du présent Contrat entre elles. Les parties reconnaissent que les
                        limitations énoncées dans cette section font partie intégrante du montant des frais
                        facturés dans le cadre de la mise à disposition du Produit au Client et que, si PayFacto
                        assumait toute autre responsabilité autre que celle énoncée dans les présentes, ces
                        frais seraient nécessairement considérablement plus élevés pour le Client.
                    </p>
                    <h6>14. Exclusion de garanties</h6>
                    <p>Le Client reconnaît que (i) PayFacto n’est pas tenue à une obligation de résultat à l’égard
                        du Produit et ne peut garantir que le Produit sera continuellement disponible pour une
                        utilisation sans interruption, et que (ii) le Produit est fourni « tel quel » sur la base « telle
                        que disponible » sans aucune représentation, garantie ou condition d’aucune sorte, et
                        PayFacto décline par la présente toutes les conditions, représentations et garanties,
                        expresses ou implicites, découlant de la loi ou autrement en ce qui concerne le Produit,
                        y compris, mais sans s’y limiter, toute (a) garantie implicite de qualité marchande ou
                        d’adéquation à un usage particulier, (b) garantie implicite découlant de l’exécution, de la
                        conduite des affaires ou de l’utilisation du commerce, (c) garantie de titre ou noncontrefaçon, ou (d) recours légal.</p>
                    <h6>15. Cession et sous-traitants</h6>
                    <p>Le Client ne peut céder aucun de ses droits ou obligations en vertu du présent Contrat
                        sans le consentement écrit préalable de Payfacto. PayFacto peut, en tout temps et sans
                        le consentement préalable du Client, céder ses droits et obligations en vertu du présent
                        Contrat. Sous réserve de ce qui précède, les dispositions du présent Contrat lient et s’en
                        tiennent compte non seulement des parties aux présentes, mais aussi de leurs
                        successeurs, ayants droit et cessionnaires. PayFacto est entièrement libre d’exécuter
                        tout ou partie de ce Contrat par l’intermédiaire d’un ou de plusieurs sous-traitants.</p>
                    <h6>16. Droit applicable, lieu et honoraires d’avocat</h6>
                    <p>Le présent Contrat est régi et interprété conformément aux lois de la province de Québec
                        et aux lois du Canada qui s’y appliquent. En cas de litige ou de réclamation découlant
                        du présent Contrat ou s’y rapportant, ou de la violation ou de l’interprétation de celui-ci,
                        les parties conviennent de soumettre leur différend à la compétence exclusive et au lieu
                        des tribunaux de la province de Québec, dans le district judiciaire de la ville de Montréal.
                        Chaque partie renonce par les présentes à tous les moyens de défense d’absence de
                        compétence personnelle et de forum non conveniens dans le cadre de toute action
                        intentée devant les tribunaux susmentionnés. Si PayFacto l’emporte dans toute action
                        contre le Client (y compris pour la collecte) découlant de ou liée à ce Contrat, PayFacto
                        aura le droit, en plus de tout autre recours à sa disposition, de recouvrer auprès du Client
                        ses honoraires d’avocat et autres experts raisonnables et les dépenses engagées à
                        l’égard d’une telle action.</p>
                    <h6>17. Conformité à l’exportation et autres restrictions</h6>
                    <p>Le produit fourni au client par PayFacto peut être assujetti aux lois canadiennes sur le
                        contrôle des exportations et les sanctions économiques. Le Client accepte de se
                        conformer à ces lois et réglementations en ce qui concerne l’accès et l’utilisation du
                        Produit. Le Client s’engage à ne pas accéder au Produit à partir d’une juridiction dans
                        laquelle la fourniture ou l’utilisation du Produit est interdite en vertu des lois et règlements
                        canadiens ou d’autres lois et règlements applicables ou de fournir l’accès au Produit à
                        tout gouvernement, entité ou individu situé dans une juridiction interdite. Le Client
                        déclare, garantit et s’engage à ce que (i) il ne soit pas un ressortissant ou une société
                        enregistrée dans une juridiction interdite; et (ii) il ne permet pas à des tiers d’accéder ou
                        d’utiliser le Produit en violation des embargos, interdictions ou restrictions à l’exportation
                        canadiens ou autres applicables.</p>
                    <h6>18. Divisibilité, force majeure, intégralité du Contrat, modification</h6>
                    <p>Si une ou plusieurs des dispositions du présent Contrat sont jugées invalides, illégales
                        ou inapplicables à quelque égard que ce soit par un tribunal compétent, la validité, la
                        légalité et le caractère exécutoire des dispositions restantes du présent Contrat ne
                        seront pas affectés. Aucune des parties ne sera réputée en défaut ou autrement
                        responsable de tout retard ou défaut de son exécution en vertu du présent Contrat (autre
                        que les obligations de paiement) en raison d’une catastrophe naturelle, d’un incendie,
                        d’un accident, d’un acte du gouvernement, d’une pénurie à grande échelle de matériaux
                        ou de fournitures, d’une défaillance du transport ou de la communication ou de
                        fournisseurs de biens ou de services, ou de toute autre cause dans la mesure où elle
                        est hors du contrôle raisonnable de cette partie. Le présent Contrat constitue l’intégralité
                        de l’accord et de l’entente entre les parties en ce qui concerne l’objet des présentes et
                        remplace toutes les communications écrites, électroniques ou orales antérieures ou
                        contemporaines, les représentations, les accords ou les ententes entre les parties à cet
                        égard. PayFacto peut modifier le présent Contrat à tout moment sur préavis. Les
                        Modalités et Conditions apparaissant au recto ou au verso de tout bon de commande,
                        accusé de réception ou confirmation qui sont différents ou en plus de ceux énoncés
                        dans le présent Contrat ne lieront pas les parties, même s’ils sont signés et retournés,
                        sauf indication expresse que ces autres Modalités et Conditions ont préséance sur les
                        termes contradictoires du présent contrat.</p>
                    <h6>19. Divers</h6>
                    <p>Le Client a examiné, compris et accepté les Modalités et Conditions énoncés dans le
                        présent Contrat et a eu l’occasion d’être représenté par un conseiller juridique, à sa
                        seule discrétion, avant de signer ce Contrat. </p>
                    <p>PayFacto peut fournir tous les avis, relevés et autres communications au Client , soit
                        par courriel, par l’intermédiaire de ses partenaires, en affichant sur son site ou par la
                        poste.</p>
                </div>

                <div class="px-6" v-if="locale === 'en'">
                    <h3>PAYFACTO LICENSES SUBSCRIPTION
                        TERMS & CONDITIONS</h3>
                    <p>
                        The standard below Terms and Conditions for a “Veloce, Maître’d and/or iShopFood
                        Licences” (The “Product”), which may be amended from time to time, are attached to the
                        Subscription Form. The Terms and Conditions, together with the Subscription Form are
                        referred herein as the “Contract”. This Contract is a binding commitment between
                        PayFacto Payments inc. (and, if the Product includes IshopFood - 9327-5329 Québec
                        inc., a subsidiary of PayFacto Payments Inc.) (“PayFacto") and the Client (as described
                        in the Subscription Form) and is not subject to the issuance of new purchase orders,
                        confirmations or other items, except as expressly stated herein.
                    </p>
                    <p>
                        By paying the Product license fees, the Client acknowledges that it has read, understood
                        and accepted the Contract, and expressly consents to be bound by it.
                    </p>
                    <p>
                        The Contract represent the entire agreement between the parties and may not be
                        superseded by any subsequent document unless agreed in writing by a duly authorized
                        representative of PayFacto. Clients with monthly subscriptions may cancel this contract
                        with 30-day notice at no cost or penalty.
                    </p>
                    <p>
                        The products/services the Client is subscribing to are listed on the Subscription Form.
                        These products/services will be made available to the Client upon receipt of payment of
                        the Product license fees. The Client understands and acknowledges that this
                        Subscription Form may only be cancelled in accordance with the terms and conditions
                        set forth in the attached Contract and that the fees are non-refundable.
                        The shipping address shown above will determine the appropriate tax jurisdiction of the
                        products and services purchased.
                    </p>
                    <h5>TERMS AND CONDITIONS OF THE PAYFACTO SERVICE CONTRACT FOR THE PRODUCT</h5>
                    <p>This service contract (“Contract”) is a contract between PayFacto Payments Inc.
                        (“PayFacto”) and the individual or legal entity (“Client”) using the Product via a paid
                        subscription. Please read this Contract carefully. By accepting these terms or using the
                        Product, whichever comes first, the Client agrees to be bound by the terms of this
                        Contract.</p>
                    <h6>1. Subscription to the Product & Integration Deadlines</h6>
                    <p>The Client hereby accepts a licence to use the Product identified on the Subscription
                        Form signed by the Client (the form is available on the PayFacto website and in the
                        Subscription (“Subscription”) documentation (i.e., quote or invoice). The specific fees
                        payable with respect to this Product are set forth in the Subscription. Each Product may
                        include updates, cloud-based services, support services, applications or documentation,
                        each of which are subject to the terms of this Contract as applicable. PayFacto may add
                        to, change or discontinue any component of the Product at any time, provided that any
                        such changes do not significantly increase the Client’s financial obligations or
                        significantly decrease PayFacto’s obligations to the Client under this Contract. The
                        Client is responsible for all actions taken under its Product account credentials,
                        regardless of whether such actions are taken by the Client, its employees,
                        subcontractors, affiliates or any other third party using the Product (“Users”). PayFacto
                        is not liable for any loss or damage related to or arising from the unauthorized use of the
                        Product identified in the Client’s account.
                    </p>
                    <p>
                        It may take up to four (4) weeks to complete the integration of the Product after the
                        Subscription process is completed. Mobile applications may be subject to longer delays
                        depending on the approval processes of the relevant Third-Party platforms.</p>
                    <h6>2. Intellectual Property Rights and Reservation of Ownership</h6>
                    <p>PayFacto is the owner and/or authorized licensee of all intellectual property rights in the
                        Product, including, without limitation, written materials, logos, names and other support
                        materials provided pursuant to this Contract and prior to the execution of this Contract.
                        Subject to the licenses expressly granted by PayFacto herein, these terms of use do not
                        transfer from PayFacto to Licensee any interest in the Product, all right, title and interest
                        in which remains solely with PayFacto or its licensors. All provisions under this
                        Agreement by PayFacto to Licensee, including, without limitation, the Product, are
                        licensed and not sold. PayFacto does not and has not transferred any ownership
                        interests in any form or manner to the Licensee. PayFacto and its licensors own the
                        Product, which are protected by Canadian law and applicable international laws, treaties,
                        and conventions regarding intellectual property or proprietary rights, inclusive of trade
                        secrets. From the date of receipt or access, Licensee agrees to use reasonable means
                        to protect the Product(s) from unauthorized use, reproduction, distribution, or
                        publication. PayFacto and its licensors reserve all rights not specifically granted in this
                        Agreement including the right to change and improve Product.</p>
                    <h6>3. Grant of Product licence</h6>
                    <p>For the term of the Subscription, PayFacto grants the Client a limited, non-exclusive,
                        non-transferable, revocable licence that does not allow a sub-licence to be granted. The
                        Client acknowledges that all right, title and interest in and to all intellectual property rights
                        in the Product, and all modifications, extensions, scripts and other derivative works of
                        the Products supplied or developed by PayFacto are the exclusive property of PayFacto
                        or its licensors. All rights not granted to the Client in this Contract are reserved by
                        PayFacto.</p>
                    <h6>4. Product licence restrictions</h6>
                    <p>
                        The Client and all Users must refrain from (and refrain from authorizing any third party
                        to):</p><p>
                    (i) decompile, disassemble, or otherwise reverse engineer, or attempt to reconstruct or
                    discover any source code, underlying ideas, algorithms, file formats or programming or
                    interoperability interfaces of or used by the Products, by any means whatsoever; (ii)
                    distribute viruses or other harmful or malicious computer code via or into the Product;
                    (iii) engage in any conduct that disrupts or impedes a third party’s use and enjoyment of
                    the Product; (iv) remove any product identification, copyright or other notices from the
                    Product; (v) sell, lease, lend, assign, sub-license, grant access, or otherwise transfer or
                    disclose the Product in whole or in part to any third party; (vi) use the Product for
                    timesharing, service bureau or hosting purposes, or otherwise use, resell, sub-license,
                    distribute or transfer, or allow others to use the Product to or for the benefit of third
                    parties; (vii) modify or incorporate the Product into or with other software or create a
                    derivative work of any part of the Product; (viii) use the output or other information
                    generated by the Product for any purpose other than as contemplated by this Contract;
                    (ix) use the Product for any use other than the Client’s internal business use; (x) use
                    unauthorized modified versions of the Product, including without limitation, for the
                    purpose of building a similar or competitive product or service or for the purpose of
                    obtaining unauthorized access to the Product; (xi) use the Product in any way that is
                    contrary to applicable local, state/provincial, federal and foreign laws, including without
                    limitation those relating to privacy, data protection, electronic communications and antispam legislation. PayFacto retains all title to, and, except as expressly licensed herein,
                    all rights to the Product, all copies, derivatives and improvements to and of the Product
                    and all related documentation and materials, or (x) sell, rent, lease, sublicense, lend,
                    assign, transfer, translate, export, or time-share the Product.
                </p>
                    <h6>5. Term of the Contract</h6>
                    <p>The Subscription begins upon the signature of this Contract (“Effective Date”) and the
                        Product will be made available to the Client upon receipt by PayFacto of the first payment
                        provided for in the Subscription Form. The Contract has an initial term of 12 months and
                        will be renewed automatically on a monthly or annual basis, as the case may be, unless
                        the Client or PayFacto provides 30-day written notice to the contrary.</p>
                    <h6>6. Payment and fees</h6>
                    <p>The Client agrees to pay to PayFacto the annual and/or monthly fees (“Fees”) set out in
                        the Subscription, in accordance with the deadline and currency stipulated in the
                        Subscription. The Client must, at all times, ensure to keep a valid payment method on
                        file with PayFacto to pay for all incurred and recurring fees and in case of any change,
                        The Client is responsible to update the information provided in Annex A hereto in due
                        time. All payments made by the Client to PayFacto under this Contract are nonrefundable and will be made through the automatic payment method specified by the
                        Client in the Subscription. All unpaid amounts due by the Client under this Contract will
                        bear interest at the rate of eighteen percent (18%) per annum, or, if lower, the maximum
                        rate permitted by law. In the event that any cheque, preauthorized payment or automatic
                        withdrawal has insufficient funds, a fee of $50.00 will be charged to the Client.</p><p>
                    Any applicable federal, state or provincial taxes, including those on goods and services
                    or sales, will be added to the fees due under this Contract. PayFacto may adjust the fees
                    set out in the price list at any time with 30-day written notice.</p><p>
                    PayFacto reserves the right to interrupt the licence of the Product if payments are not
                    made on time.</p>
                    <h6>7. Confidential information, Privacy and Data Storage</h6>
                    <p>The information collected by PayFacto will be used for underwriting Client’s
                        Subscription and if accepted, to provide the Product to the Client. The information
                        provided by the Client will be kept confidential and stored in a secure environment.
                        Your personal information may be processed and stored in the U.S. by a third-party
                        service provider acting on PayFacto’s behalf and may be subject to disclosure to law
                        enforcement and regulatory agencies in the U.S.</p><p>
                    PayFacto uses and protects information relating to the Client, including information
                    transmitted through the Product, in accordance with PayFacto’s Privacy Policy, available
                    at www.payfacto.com (“Site”). The Client agrees and consents to PayFacto using and
                    sharing non-personal data with third parties to build anonymous data profiles, provide
                    segmented marketing information, create aggregate statistical reports, and to improve
                    PayFacto’s current and future products and services.</p><p>
                    PayFacto and the Client (and its Users) will treat as confidential all information received
                    from the other party pursuant to or in connection with this Contract. However, each party
                    may disclose such information on a confidential basis to its immediate legal and financial
                    advisors as required in the ordinary course of business. Notwithstanding the foregoing,
                    the restrictions set forth above do not apply to (i) information previously known to the
                    receiving party without reference to the confidential information of the disclosing party,
                    (ii) information that is or becomes publicly known through no wrongful act of the receiving
                    party, (iii) information that is independently developed by either party, or (iv) information
                    required to be disclosed under any applicable law. The foregoing does not prevent
                    PayFacto from using the Client’s name and/or brand in its marketing and training
                    materials or the Client’s data on an anonymous, compiled basis.</p>
                    <h6>8. Client warranties and indemnities</h6>
                    <p>
                        The Client represents and warrants that as of the Effective Date and throughout the term
                        of the Subscription that (i) the Client is duly authorized to enter into this Contract and the
                        Client and all Users are duly authorized to use the Products, (ii) the Client and all Users
                        are and will remain in compliance with all applicable laws and regulations regarding their
                        activities relating to this Contract, including but not limited to, privacy laws; and (iii) if the
                        Client or any of the Users import lists into the Products for the purpose of sending
                        electronic communications (e.g. emails, text messages) to the contacts contained in
                        such lists, or otherwise collect email addresses for the purpose of sending electronic
                        messages, the Client warrants that each person on the said list has previously elected
                        to receive promotional electronic communications from the Client and that the content of
                        these communications sent by the Client comply with applicable laws and regulations.
                        The Client indemnifies, defends, and holds PayFacto and its management, employees,
                        agents and affiliates harmless against all losses, expenses, liabilities, damages and
                        costs, including but not limited to, reasonable legal fees (collectively, the “Costs”) to the
                        extent that such Costs are incurred due to a failure by the Client or any User to comply
                        with any representation, warranty or other obligation set forth in this Contract. The Client
                        and each of its Users undertake to comply with all PayFacto policies and all laws, rules
                        and regulations related to the use of the Products.</p><p>
                    The Client undertakes to indemnify and hold PayFacto harmless from any costs, fines
                    or damages that PayFacto may suffer as a result of any breach of the provisions of this
                    Contract by the Client or any User.
                </p>
                    <h6>9. Submissions</h6>
                    <p>The Client acknowledges that any material it provides to PayFacto, including but not
                        limited to, questions, comments, suggestions, ideas, plans, notes, drawings, original or
                        creative materials, or other information relating to PayFacto or the Products, whether
                        provided in the form of email, feedback forms or any other format, belongs exclusively
                        to PayFacto without any requirement to acknowledge or compensate the Client.</p>
                    <h6>10. Third-party services</h6>
                    <p>
                        “Third-Party Services” refers to third-party products, applications, services, software,
                        products, networks, systems, directories, websites, databases and information that one
                        or more Product(s) are linked to or that the Client may connect to or enable using one
                        or more Product(s).</p><p>
                    The Client may decide to enable, access or use any Third-Party Services. The Client
                    acknowledges that access to and use of such Third-Party Services is governed solely
                    by the terms of such Third-Party Services, and that PayFacto is not responsible for their
                    content or the manner in which they handle data (including Client data).</p><p>
                    Any use of Third-Party Services by the Client will be solely between the Client and the
                    applicable third-party provider.</p><p>
                    The Client irrevocably waives any claim against PayFacto with respect to such ThirdParty Services. PayFacto is not liable for any damage or loss caused or alleged to be
                    caused by or in connection with the Client’s enablement, access or use of such ThirdParty Services or the Client’s reliance on the privacy practices, data security processes
                    or other policies of such Third-Party Services.
                </p>
                    <h6>11. Maintenance activities</h6>
                    <p>PayFacto may need to perform scheduled or unscheduled repairs or maintenance
                        activities, or remotely patch or update the Product, which may temporarily degrade the
                        quality of the services or result in a partial or complete outage of the Product.
                        PayFacto will attempt to perform repairs or maintenance during periods that cause the
                        least disruption. The Client must cooperate, if necessary, so the said work can be
                        performed.</p><p>
                    PayFacto will use its commercially reasonable efforts to provide the Client with customer
                    support to help resolve issues.</p><p>
                    PayFacto, or the distributor as the case may be, provides support for the Product only.
                    PayFacto, or the distributor as the case may be, disclaims all liability of any kind
                    whatsoever with regards to any other product used or licensed by the Client, even if in
                    conjunction with the Product.</p>
                    <h6>12. Termination and suspension</h6>
                    <p>
                        <b>12.1. Termination for breach</b> In the event of a breach of this Contract by one party, the
                        other party may terminate this Contract by giving the breaching party thirty (30) day
                        written notice setting out the nature of the breach in detail and the non-breaching party’s
                        intention to terminate the Contract upon expiry of the thirty (30) day period if the
                        breaching party has not cured the breach. In such an event, the Contract will
                        automatically terminate.</p><p>
                    Notwithstanding the foregoing, if the Client is (i) in breach of any material provision of
                    this Contract, including the licence restrictions in Section 4, the terms of payment in
                    Section 6, the confidentiality restrictions in Section 7, or the Client warranties and
                    indemnities in Section 8, or (ii) has made an assignment of property, is insolvent or is
                    bankrupt, PayFacto may, at its sole discretion, immediately suspend or terminate the
                    Client’s use of the Product. Any such suspension or termination will not relieve the Client
                    of any of its obligations under this Contract, nor will it entitle the Client to any refund of
                    payments made previously. Furthermore, in such an event, the Client and/or any User
                    must understand and accept that they have no recourse whatsoever against PayFacto.</p><p>
                    <b>12.2. Temporary suspension</b> PayFacto may temporarily suspend the Client’s access to
                    the Product immediately without notice if PayFacto, in its discretion, believes that: (i)
                    such suspension is required by law; (ii) the Client has breached any of the obligations
                    set forth in this Contract, such as a monthly payment; or (iii) there is a risk to the Client’s
                    security or privacy. A suspension of the Client’s access to the Products will not limit or
                    waive PayFacto’s rights to terminate this Contract or the Client’s access to the Products.</p><p>
                    <b>12.3. Effect of termination</b> Upon termination of this Contract, the Client and any Users
                    must discontinue their use of the product. Notwithstanding the foregoing, termination of
                    this Contract by PayFacto will not limit or end the Client’s obligation to pay all fees owing,
                    nor will it prevent PayFacto from pursuing any other remedies available to it, including
                    injunctive relief. Sections 4, 6-14 and 16-19 will survive termination of this Contract,
                    along with any other provisions that are intended by their terms to survive.</p><p>
                    <b>12.4. Client data</b> The Client understands and agrees that PayFacto has no control over
                    the content of the data processed by PayFacto on the Client’s behalf. The Client is
                    responsible for taking its own steps to maintain appropriate security, protection and
                    backup of its data and software, including the use of appropriate backup and archiving
                    practices. The Client remains responsible for handling and processing notices regarding
                    the personal data of its Clients.</p><p>
                    The Client understands and agrees that PayFacto is not responsible for any loss or
                    corruption of its Client data or other software. The Client acknowledges that following
                    the termination of the Client’s account and/or use of the Product, PayFacto may
                    immediately deactivate the Client’s account and delete the Client’s data. The Client
                    further acknowledges that PayFacto is not liable to the Client or to any third party for the
                    termination of the Client’s access to the Product or deletion of the Client’s data.
                </p>
                    <h6>13. Limitation of liability</h6>
                    <p>
                        PayFacto and its partners undertake to provide access to the Product identified in the
                        Subscription, in accordance with the terms and conditions stipulated in this Contract, for
                        as long as the Client pays the monthly or annual fees (as the case may be). However,
                        PayFacto will be released from its liability if there is: (i) any willful or deliberate
                        infringement or misappropriation by the Client of the intellectual property rights of
                        PayFacto, its partners or suppliers, or (ii) the Client’s indemnity obligations under this
                        Contract to the maximum extent permitted by law. Neither party will be held liable for any
                        consequential, collateral, special or punitive damages, or lost profits, royalties or data,
                        or the cost of procuring substitute goods or services, whether for breach of contract,
                        warranty, civil liability, statutory remedy or any obligation arising therefrom or otherwise,
                        and irrespective of whether each party has advised or been advised of the possibility of
                        such loss or damage. The Client hereby waives any claim that these exclusions deprive
                        it of an adequate remedy. The parties acknowledge that the provisions of this section
                        fairly allocate the risks under this Contract between them. The parties acknowledge that
                        the limitations set forth in this section are integral to the amount of fees charged in
                        connection with making the Product available to the Client and that, were PayFacto to
                        assume any further liability other than as set forth herein, such fees would of necessity
                        be set substantially higher.
                    </p>
                    <h6>14. Disclaimer of warranties</h6>
                    <p>The Client acknowledges that (i) PayFacto cannot guarantee the results generated
                        through the Product or that the Product will be continuously available for use without
                        interruption, and (ii) the Product is provided “as is” on as “as available” basis without any
                        representation, warranty or condition of any kind, and PayFacto hereby disclaims all
                        conditions, representations and warranties whether express or implied, arising by law or
                        otherwise with respect to the Product, including, but not limited to, any (a) implied
                        warranty of merchantability or fitness for a particular purpose, (b) implied warranty
                        arising from course of performance, course of dealing, or usage of trade, (c) warranty of
                        title or non-infringement, or (d) statutory remedy.</p>
                    <h6>15. Assignment and subcontractors</h6>
                    <p>The Client may not assign any of its rights or obligations under this Contract without
                        Payfacto’s prior written consent. PayFacto may, at any time and without the Client’s prior
                        consent, assign its rights and obligations under this Contract. Subject to the foregoing,
                        the provisions of this Contract are binding on and inure to the benefit not only of the
                        parties hereto but also to their successors, assigns and transferees. PayFacto is entirely
                        free to perform all or any part of this Contract through one or more subcontractors.</p>
                    <h6>16. Governing law, venue and attorneys’ fees</h6>
                    <p>This Contract is governed by and interpreted in accordance with the laws of the Province
                        of Quebec and the laws of Canada applicable therein. In the event of any controversy or
                        claim arising out of or relating to this Contract, or the breach or interpretation thereof,
                        the parties agree to submit their dispute to the exclusive jurisdiction of and venue in the
                        courts of the Province of Quebec, in the judicial district of the city of Québec. Each party
                        hereby waives all defences of lack of personal jurisdiction and forum non conveniens in
                        connection with any action brought in the foregoing courts. If PayFacto prevails in any
                        action against the Client (including for collection) arising from or relating to this Contract,
                        PayFacto will be entitled, in addition to any other remedies available to it, to recover from
                        the Client its reasonable attorneys’ and other experts’ fees and expenses incurred with
                        respect to such action</p>
                    <h6>17. Export compliance and other restrictions</h6>
                    <p>The Product provided to the Client by PayFacto may be subject to Canadian export
                        control and economic sanctions laws. The Client agrees to comply with such laws and
                        regulations as they relate to the access to and use of the Product. The Client agrees not
                        to access the Product from any jurisdiction in which the provision or use of the Product
                        is prohibited under Canadian or other applicable laws and regulations or provide access
                        to the Product to any government, entity or individual located in any prohibited
                        jurisdiction. The Client represents, warrants, and covenants that (i) it is not a national of,
                        or company registered in, any prohibited jurisdiction; and (ii) it does not permit third
                        parties to access or use the Product in violation of any Canadian or other applicable
                        export embargoes, prohibitions or restrictions.</p>
                    <h6>18. Severability, force majeure, entire Contract, amendment</h6>
                    <p>If one or more of the provisions of this Contract are held to be invalid, illegal or
                        unenforceable in any respect by a court of competent jurisdiction, then the validity,
                        legality and enforceability of the remaining provisions of this Contract will be unaffected.
                        Neither party will be deemed in default or otherwise liable for any delay in or failure of its
                        performance under this Contract (other than payment obligations) by reason of any
                        natural disaster, fire, accident, act of government, large-scale shortage of materials or
                        supplies, failure of transportation or communication or of suppliers of goods or services,
                        or any other cause to the extent it is beyond the reasonable control of such party. This
                        Contract constitutes the entire agreement and understanding between the parties with
                        respect to the subject matter hereof and supersedes all prior or contemporaneous
                        written, electronic or oral communications, representations, agreements or
                        understandings between the parties with respect thereto. PayFacto may amend this
                        Contract at any time upon prior notice. Any terms and conditions appearing on the face
                        or reverse side of any purchase order, acknowledgment, or confirmation that are
                        different from or in addition to those set forth in this Contract will not be binding on the
                        parties, even if signed and returned, unless expressly stated that such other terms and
                        conditions take precedence over conflicting terms of this Contract.</p>
                    <h6>19. Miscellaneous</h6>
                    <p>The Client has reviewed, understood and accepted the terms and conditions set forth in
                        this Contract and has had the opportunity to be represented by legal counsel, at its sole
                        discretion, prior to signing this Contract. </p>
                    <p>PayFacto may provide any and all notices, statements and other communications to the
                        Client either through email, through its partners, by posting on its site or by mail.</p>
                </div>
            </slot>
        </LegalInformation>
    </Dialog>
</template>

<style lang="scss">
.p-dialog {
    border-radius: 32px;
    .p-dialog-title {
        font: normal normal bold 24px/29px Metropolis;
    }
    .p-dialog-header {
        border-top-right-radius: 32px;
        border-top-left-radius: 32px;
    }
}

</style>

<i18n lang="yaml">
en:
    title: Terms & Conditions
fr:
    title: Conditions d'utilisation
</i18n>

export default function useBeamer(productId) {
    const user = useLocalStorage('user', {});
    const locale = useLocalStorage('locale', 'en');

    const loadBeamer = () => {
        window.beamer_config = {
            product_id: productId,
            selector: '.announcements__action',
            language: locale.value
        };
        (function() {
            const beamer = document.createElement('script');
            beamer.type = 'text/javascript';
            beamer.async = true;
            beamer.id = 'beamer-script';
            beamer.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.append(beamer, s);
        })();
    };

    function updateBeamerLanguage(newLanguage) {
        window.beamer_config = {
            product_id: productId,
            selector: '.announcements__action',
            user_firstname: user.value.firstName,
            user_lastname: user.value.lastName,
            user_email: user.value.email,
            language: newLanguage
        };

        if(typeof Beaner) {
            nextTick(() => {
                Beamer.remove();
                Beamer.started = false;
                Beamer.init(window.beamer_config);
            });
        }
    }

    watch(locale, locale => {
        updateBeamerLanguage(locale);
    })

    watch(user, user => {
        window.beamer_config = {
            ...window.beamer_config,
            user_firstname: user.firstName,
            user_lastname: user.lastName,
            user_email: user.email,
            language: locale.value
        };
    }, {immediate: true})

    onMounted(() => {
        loadBeamer();
    });
}

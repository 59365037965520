indicators:
  drawerOpenings:
    title: Drawer openings
    description: Number of times the drawer was opened
  giftCardAmount:
    title: Gift card loads amount
    description: Total amount of gift card loads
  itemCorrections:
    title: Item corrections
    description: Number of item corrections
  itemCorrectionsAmount:
    title: Item corrections amount
    description: Total amount of item corrections
  loyaltiesAmount:
    title: Loyalties amount
    description: Total amount of loyalty redemptions
  paidOuts:
    title: Paid outs
    description: Number of paid outs
  paidOutsAmount:
    title: Paid outs amount
    description: Total amount of paid outs
  refunds:
    title: Refunds
    description: Number of refunds
  refundsAmount:
    title: Refunds amount
    description: Total amount of refunds
  voids:
    title: Voids
    description: Number of voids
  voidsAmount:
    title: Voids amount
    description: Total amount of voids
  grossSalesAmount:
    title: Gross sales amount
    description: Total amount of gross sales
  discountsAmount:
    title: Discounts amount
    description: Total amount of discounts
  invoicesDiscount:
    title: Invoices discount
    description: Total amount of invoices discount
  itemsDiscount:
    title: Items discount
    description: Total amount of items discount
  netSalesAmount:
    title: Net sales amount
    description: Total amount of net sales
  refundsTotalAmount:
    title: Refunds total amount
    description: Total amount of refunds
  taxesAmount:
    title: Taxes amount
    description: Total amount of taxes
  gratuityAmount:
    title: Gratuity amount
    description: Total amount of gratuity
  tipsAmount:
    title: Tips amount
    description: Total amount of tips
  tipsTotalAmount:
    title: Tips total amount
    description: Total amount of tips
  customers:
    title: Customers
    description: Number of customers
  meals:
    title: Meals
    description: Number of meals
  orders:
    title: Orders
    description: Number of orders
  debitCreditTotalAmount:
    title: Debit credit total amount
    description: Total amount of debit credit
  debitCreditTipsAmount:
    title: Debit credit tips amount
    description: Total amount of debit credit tips
  averageNetSales:
    title: Average net sales
    description: Average net sales
  averageGrossSales:
    title: Average gross sales
    description: Average gross sales
  roundingAmount:
    title: Rounding amount
    description: Total amount of rounding
  cashTipsAmount:
    title: Cash tips amount
    description: Total amount of cash tips
  surchargeAmount:
    title: Surcharge amount
    description: Total amount of surcharge
  netCashAmount:
    title: Net cash amount
    description: Total amount of net cash
  cashDepositAmount:
    title: Cash deposit amount
    description: Total amount of cash deposit
  paidInsAmount:
    title: Paid ins amount
    description: Total amount of paid ins
  cashDiscountAmount:
    title: Cash discount amount
    description: Total amount of cash discount
  locationSales:
    title: Location sales (top 10)
    description: Top 10 locations by sales
  consolidatedHourlySales:
    title: Sales per hour
    description: Sales per hour
